import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterComponent from './routers/Routes';
import { COUNTRY, COUNTRY_VAL } from 'shared/constants';
import { imgHabi, imgTuHabi } from 'image';
import { Helmet } from 'react-helmet';

function App() {
  const image = COUNTRY === COUNTRY_VAL.CO ? imgHabi : imgTuHabi;
  const uniqueUrl = `${image}?t=${new Date().getTime()}`;
  useEffect(() => {
    const iconLink: any = document.querySelector("link[rel~='icon']");
    if (iconLink) {
      const newHref = uniqueUrl;
      iconLink.href = newHref;
    }
  }, []);
  return (
    <BrowserRouter>
      <Helmet>
        <title>Seller Asignadores</title>
      </Helmet>
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
