import {
  MailSeller,
  StateSeller,
  TableStyled,
  TbodyStyled,
  TdStyled,
  ThStyled,
  TheadStyled,
  TrBodyStyled,
  TrStyled,
} from './GenericTable.styles';
import { useEffect, useState } from 'react';
import type { IGenericColumn, IGenericRow, IGenericTableProps } from './model';
import { leaderMedal } from 'image';

const GenericTable = ({ defaultColumns, data }: IGenericTableProps) => {
  const [columns, setColumns] = useState<IGenericColumn[] | []>();
  const [rows, setRows] = useState<IGenericRow[] | []>();

  useEffect(() => {
    if (defaultColumns) {
      const newColumns: IGenericColumn[] = [];
      defaultColumns.forEach((item) => {
        newColumns.push({ ...item, order: '' });
      });
      setColumns(newColumns);
    }
    if (data) setRows(data);
  }, [defaultColumns, data]);

  const getColumns = () => {
    if (columns) {
      return columns.map((column: IGenericColumn) => (
        <ThStyled
          order={column.order}
          key={`column-${column.id}`}
          sort={column.sort}
          onClick={() => {
            column.sort && handleOrder({ id: column.id, name: column.name });
          }}
        >
          {column.label}
        </ThStyled>
      ));
    }
  };

  const handleOrder = ({ id, name }: any) => {
    const newColumns: any = [];
    if (columns && name) {
      columns.forEach((column) => {
        if (column.id === id) {
          const order = column.order === 'desc' ? 'asc' : 'desc';
          newColumns.push({
            ...column,
            order,
          });
          const collator = new Intl.Collator();
          if (order === 'desc') {
            data.sort((a, b) =>
              collator.compare(a[name.toLowerCase()], b[name.toLowerCase()])
            );
          } else {
            data.sort((a, b) =>
              collator.compare(b[name.toLowerCase()], a[name.toLowerCase()])
            );
          }
        } else {
          newColumns.push({ ...column, order: '' });
        }
      });
      setColumns(newColumns);
    }
  };

  const getRows = () => {
    if (rows) {
      return rows.map((row: IGenericRow) => {
        return (
          <TrBodyStyled key={`row-${row.id}`}>{getCells(row)}</TrBodyStyled>
        );
      });
    }
  };

  const getCells = (row: IGenericRow) => {
    if (row && columns) {
      return columns.map((column: IGenericColumn) => (
        <TdStyled key={`td-${row.id}-${column.name}`}>
          {column.name === 'seller_state_label' && (
            <StateSeller
              status={`${row[column.name as keyof IGenericRow]}`.toLowerCase()}
            >
              {row[column.name as keyof IGenericRow]}
            </StateSeller>
          )}
          {column.name === 'email' && (
            <MailSeller>{row[column.name as keyof IGenericRow]}</MailSeller>
          )}
          <div className='text-image'>
            {column.name !== 'seller_state_label' &&
              column.name !== 'email' &&
              column.name !== 'team_name' &&
              row[column.name as keyof IGenericRow]}
            {column.name === 'team_name' &&
              (row.leader_seller
                ? 'Líder'
                : row[column.name as keyof IGenericRow])}
            {column.name === 'name' && !!row.leader_seller && (
              <>
                <img src={leaderMedal} alt='leader' />
              </>
            )}
          </div>
        </TdStyled>
      ));
    }
  };

  return (
    <TableStyled>
      <TheadStyled>
        <TrStyled>{getColumns()}</TrStyled>
      </TheadStyled>

      <TbodyStyled>{getRows()}</TbodyStyled>
    </TableStyled>
  );
};

const defaultProps: IGenericTableProps = {
  defaultColumns: [],
  data: [],
  bordered: true,
  borderless: false,
  striped: false,
};

GenericTable.defaultProps = defaultProps;

export default GenericTable;
