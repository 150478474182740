import styled, { keyframes } from 'styled-components';

import {
  electricPurple600,
  monserratRegular,
  white,
  robotoRegular,
  error,
  scarpaGray500
} from '@habitech/shared';
import { loginBackground } from 'image';

export const HomePrincipalContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const HomeLeftContainer = styled.div`
  width: 40%;
  height: 100%;
  background: ${white};
`;

export const HomeRightContainer = styled.div`
  width: 60%;
  height: auto;
  background: url("${loginBackground}") no-repeat center;
  background-size: cover;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
  .information {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    .return {
      margin-right: 16px;
      cursor: pointer;
    }
  }
  .title {
    font-family: ${monserratRegular};
    font-size: 40px;
    color: ${electricPurple600};
    font-weight: 600;
  }
  .subtitle {
    font-family: ${monserratRegular};
    font-size: 16px;
    font-weight: 400;
    color: ${scarpaGray500};
    margin: 32px 0;
    .strong {
      font-weight: 700;
    }
  }
`;

export const CountryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const CountryContainer = styled.div`
  border-radius: 8px;
  background: ${white};
  box-shadow: 0px 8px 16px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  padding: 21px 24px;
  cursor: pointer;
  height: 80px;
  width: 180px;
  span {
    color: ${electricPurple600};
    font-family: ${monserratRegular};
    font-size: 16px;
    font-weight: 400;
    display: block;
  }
  img {
    margin-right: 10px;
    width: 32px;
  }
  &.active, &:hover {
    border: 1px solid ${electricPurple600};
  }
`;

export const LoginWrapper = styled.div.attrs({
  className: "LoginWrapper",
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  font-weight: normal;
  margin-top: 1rem;
  font-size: 16px;
`;

export const StyledErrorLogin = styled.div.attrs({
  className: "StyledErrorLogin",
})`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 12px;
    font-family: ${robotoRegular};
    color: ${error};
  }
`

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const HomeLoadingContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const RotatingImage = styled.img`
  animation: ${rotateAnimation} 2s linear infinite;
  margin: 0 auto;
`;
