import { ServicesMx } from 'service/api';
import { type IRequestTeams } from './model';

export const getAllStates = async () => {
  return await ServicesMx.get('/states');
};

export const getSearchedZones = async (search: string) => {
  return await ServicesMx.get('/zones', { params: { search } });
};

export const getAllMxSellers = async (params: {
  leader?: boolean;
  pagination?: number;
  limit?: number;
  for_teams?: boolean;
}) => {
  return await ServicesMx.get('/sellers_mx', { params });
};

export const saveTeam = async (params: any) => {
  return await ServicesMx.post('/teams', params);
};

export const getTeamById = async (
  id: string,
  params: { pagination?: number }
) => {
  return await ServicesMx.get(`/teams/${id}`, { params });
};

export const updateTeam = async (id: string, params: any) => {
  return await ServicesMx.put(`/teams/${id}`, params);
};

export const getTeamsList = async () => {
  try {
    const { data } = await ServicesMx.get<IRequestTeams>(`/teams`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getTeamsListCoordination = async () => {
  try {
    const { data } = await ServicesMx.get(`/teams`, {
      params: { with_coordinations: 1 },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteTeam = async (id: number) => {
  try {
    const { data } = await ServicesMx.delete(`/teams/${id}`, {
      data: { country: localStorage.getItem('country') }
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
