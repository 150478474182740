import { getLocalStorageItem } from 'utils/funcionHelper';
import { electricPurple600, skyBlue600, scarpaGray600 } from '@habitech/shared';
import { pointGo } from 'image';

const { role } = getLocalStorageItem('TOKEN') ?? {};

export const selectAction = [
  {
    title: 'Equipos',
    subtitle:
      role !== 'equipo_bo'
        ? 'Consulta los equipos de trabajo'
        : 'Crear o editar equipos de trabajo',
    url: '/equipos',
    color: electricPurple600,
    img: pointGo,
  },
  {
    title: 'Sellers',
    subtitle: 'Administra sellers',
    url: '/home',
    color: skyBlue600,
    img: pointGo,
  },
];
export const selectActionCO = [
  {
    title: 'Sellers',
    subtitle: 'Administra sellers',
    url: '/home',
    color: electricPurple600,
    img: pointGo,
  },
  {
    title: 'Zonas Maduras',
    subtitle: 'Gestiona las zonas Maduras',
    url: '/zonas-maduras',
    color: skyBlue600,
    img: pointGo,
  },
  {
    title: 'Zonas Medianas',
    subtitle: 'Asigna sellers',
    url: '/zonas-medianas',
    color: scarpaGray600,
    img: pointGo,
  },
];
