import { useEffect } from 'react';
import { createRoot, type Root } from 'react-dom/client';
import Toast from 'custom/toast/Toast';
import { imgWarnings } from 'image';

export const useToast = () => {
  let root: Root;
  const showToast = ({
    type = 'warning',
    title,
    subtitle,
    icon = imgWarnings,
  }: {
    type?: string;
    title: string;
    subtitle?: string;
    icon?: string;
  }) => {
    if (!root) {
      root = createRoot(document.getElementById('toast') as HTMLElement);
    }
    root.render(
      <Toast
        onClose={() => {
          root?.unmount()
        }}
        icon={icon}
        title={title}
        subtitle={subtitle}
        type={type}
      />
    );
  };

  useEffect(() => {
    if (!document.getElementById('toast')) {
      const toastContainer = document.createElement('div');
      toastContainer.id = 'toast';
      document.body.appendChild(toastContainer);
    }
  }, []);
  return {
    showToast,
  };
};