import { type Itoken } from 'components/login/loginGoogle';

export const COUNTRY = localStorage.getItem('country');

export enum COUNTRY_VAL {
  CO = 'CO',
  MX = 'MX',
}
export const ROLE_NAMES = {
  COMMERCIAL: 'Líder comercial',
  BO: 'Administrador',
};

export enum ROUTER_URL {
  EDIT = '/form/edit',
  CREATE = '/form/create',
  HOME = '/home',
  LOGIN = '/',
  BO = '/bo',
  ACTIONS = '/actions',
  MATURES_ZONE = '/zonas-maduras',
  MATURES_ZONE_CREATE = '/zonas-maduras/create',
  MATURES_ZONE_EDIT = '/zonas-maduras/edit',
  MEDIAN_ZONE = '/zonas-medianas',
}

export const TOKEN: Itoken = JSON.parse(
  localStorage.getItem('TOKEN') as string
);

export enum ROLES {
  TEAM_SELLER = 'equipo_comercial',
  TEAM_BO = 'equipo_bo',
}
