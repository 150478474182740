import React from 'react';
import styled, { css } from 'styled-components';
import {
  robotoRegular,
  scarpaGray500,
  // skyBlue50,
  // skyBlue200,
  white,
} from '@habitech/shared';

interface TooltipProps {
  text: string;
  children?: React.ReactNode;
}

const commonItemStyles = css`
  display: flex;
  padding: 4px 8px;
  border-radius: 100px;
  font-size: 12px;
  font-family: ${robotoRegular};
  font-weight: 600;
  background-color: ${white};
`;

const TooltipContainer = styled.div`
  position: relative;
  .item {
    ${commonItemStyles}
  }
  .item-selected {
    ${commonItemStyles}
  }
  .button-assigned {
    ${commonItemStyles}
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
    flex-wrap: wrap;
    align-items: center;
    padding: 0 4px 0 12px;
    height: 32px;
    cursor: auto;

    p {
      width: calc(100% - 24px);
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: ${scarpaGray500};
    }

    button {
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: 2px solid #f7f9fc;
      border-radius: 50%;
      display: grid;
      place-items: center;
      padding: 0;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .size-zones {
    width: 150px;
  }
`;

const HoverDiv = styled.div`
  cursor: pointer;
`;

const TooltipBox = styled.div`
  display: none;
  font-family: ${robotoRegular};
  font-size: 12px;
  position: absolute;
  padding: 4px;
  background-color: #333;
  color: #fff;
  border-radius: 6px;
  z-index: 1;
  top: 70%;
  left: 10px;

  ${TooltipContainer}:hover & {
    display: block;
  }
`;

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  return (
    <TooltipContainer>
      <HoverDiv>{children}</HoverDiv>
      {text?.length > 1 && <TooltipBox>{text}</TooltipBox>}
    </TooltipContainer>
  );
};

export default Tooltip;
