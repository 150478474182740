import { robotoRegular, size } from '@habitech/shared';
import styled from 'styled-components';

export const TeamManageContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${robotoRegular};
`;

export const ManageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

export const TeamManageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ManageFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 7;
  @media screen and (max-width: ${size.tablet.max}) {
    grid-column: 1 / 5;
  }
`;

export const ManageMapContainer = styled.div`
  display: flex;
  grid-column: 7 / 13;
  @media screen and (max-width: ${size.tablet.max}) {
    grid-column: 5 / 9;
  }
`;
export const MapCard = styled.div.attrs({
  className: 'MapCard',
})`
  height: 77.5dvh;
  display: grid;
  place-items: center;
  width: 100%;

  .WrapperMaps {
    box-shadow: 0px 4px 6px 0px #3031331a;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
`;
