import { useEffect, useState } from 'react';
import {
  GoogleMap,
  Polygon,
  InfoWindow,
  useJsApiLoader,
} from '@react-google-maps/api';

import { disabledBorder, skyBlue500, skyBlue600 } from '@habitech/shared';
import { COUNTRY_VAL } from 'shared/constants';
import { WrapperMaps } from './Maps.style';
import type { IMaps, LatLng } from './model';

const Maps = ({
  data,
  width,
  fillColor = skyBlue500,
  strokeColor = skyBlue600,
  mapStyles = {},
}: IMaps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_KEY_MAPS as string,
  });

  const [coordenadasPoligonos, setCoordenadasPoligonos] = useState([
    {
      label: '',
      coordinates: '',
      active: 0,
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  const location = localStorage.getItem('country');
  const [showLabel, setShowLabel] = useState(NaN);
  const initialMx = {
    lat: 19.42847,
    lng: -99.12766,
  };

  const initialCo = {
    lat: 4.60971,
    lng: -74.08175,
  };

  const showDescription = (i: number) => {
    if (i === showLabel) {
      setShowLabel(NaN);
    } else {
      setShowLabel(i);
    }
  };

  const coord = (coordinate: string) => {
    return coordinate
      ?.replace('MULTIPOLYGON(((', '')
      ?.replace(')))', '')
      ?.split('),(')
      ?.map((polygon) =>
        polygon?.split(',')?.map((coordString) => {
          const [lng, lat] = coordString.split(' ');
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        })
      );
  };

  const centerWindowLabel = (polygonCoords: LatLng[]) => {
    const totalCoords = polygonCoords.length;
    try {
      const { lat, lng } = polygonCoords.reduce(
        (acc, { lat, lng }) => ({
          lat: acc.lat + Number(lat),
          lng: acc.lng + Number(lng),
        }),
        { lat: 0, lng: 0 }
      );
      return { lat: lat / totalCoords, lng: lng / totalCoords };
    } catch (error) {}
  };

  useEffect(() => {
    setCoordenadasPoligonos(data);
    // return () => {
    //   setCoordenadasPoligonos(data);
    // };
  }, [data]);

  const centerData =
    coordenadasPoligonos.length > 0
      ? coord(coordenadasPoligonos[0]?.coordinates)
      : null;

  const posicionInicial =
    centerData && !isNaN(centerData[0][0].lat)
      ? centerData[0][0]
      : location === COUNTRY_VAL.CO
      ? initialCo
      : initialMx;
  const containerStyle = {
    width: !width ? '100%' : width,
    height: '77.5dvh',
    border: `1px solid ${disabledBorder}`,
    borderRadius: '8px',
  };
  if (loadError) return <p>Error al cargar el mapa</p>;
  return (
    <WrapperMaps>
      {isLoaded && (
        <GoogleMap
          center={posicionInicial}
          zoom={coordenadasPoligonos.length === 1 ? 14 : 12}
          mapContainerStyle={containerStyle}
          options={mapStyles}
        >
          {coordenadasPoligonos?.map(({ coordinates, label }, i) => {
            const coordCurrent = coordinates ? coord(coordinates) : [];
            return (
              <div key={JSON.stringify(coordinates)}>
                {coordCurrent.length > 0 && !isNaN(coordCurrent[0][0].lat) && (
                  <Polygon
                    key={i}
                    paths={coordCurrent}
                    options={{
                      fillColor,
                      strokeColor,
                      strokeWeight: 2,
                    }}
                    onClick={() => {
                      showDescription(i);
                    }}
                  />
                )}
                {showLabel === i && !isNaN(coordCurrent[0][0].lat) && (
                  <InfoWindow position={centerWindowLabel(coordCurrent[0])}>
                    <p>{label}</p>
                  </InfoWindow>
                )}
              </div>
            );
          })}
        </GoogleMap>
      )}
    </WrapperMaps>
  );
};

export default Maps;
