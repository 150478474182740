import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectAction, selectActionCO } from './data/dataActions';
import { COUNTRY_VAL } from 'shared/constants';
import {
  StyledButtonActions,
  StyledSubtitle,
  StyledSubtitleActions,
  StyledTitle,
  StyledTitleteam,
  GreetingContainer,
  ContainerSubtitle,
  LineDivider,
} from './SelectActions.styles';

const SelectActions = () => {
  const [country, setCountry] = useState<string>('MX');

  useEffect(() => {
    const storedValue = localStorage.getItem('country');
    if (storedValue) {
      setCountry(storedValue);
    }
  }, []);
  const navigate = useNavigate();
  const getUserLocalStore = JSON.parse(localStorage.getItem('user') as string);
  const getUserName = getUserLocalStore?.name?.split(' ')[0];

  const onNavigate = (url: string) => {
    navigate(url);
  };

  const action = country === COUNTRY_VAL.CO ? selectActionCO : selectAction;
  return (
    <div className='grid-full grid-habi'>
      <GreetingContainer>
        <StyledTitle>¡Hola, {getUserName}!</StyledTitle>
        <StyledSubtitle>
          Selecciona la herramienta que deseas utilizar
        </StyledSubtitle>
      </GreetingContainer>
      <LineDivider />
      <div className='grid-full grid-habi'>
        {action.map((item) => (
          <StyledButtonActions
            country={country}
            color={item.color}
            onClick={() => {
              onNavigate(item.url);
            }}
            key={item.title}
          >
            <ContainerSubtitle>
              <StyledSubtitleActions>{item.subtitle}</StyledSubtitleActions>
              <img src={item.img} alt='go' width={32} height={32} />
            </ContainerSubtitle>
            <StyledTitleteam>{item.title}</StyledTitleteam>
          </StyledButtonActions>
        ))}
      </div>
    </div>
  );
};

export default SelectActions;
