import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { selectIsLoading } from 'store/slice/userSlice';
import { selectTeams } from 'store/slice/teamSlice';
import { ROUTER_URL } from 'shared/constants';
import useTeams from 'hook/useTeams';

import { Button } from '@habitech/call-to-action';
import { Loader } from '@habitech/loader';

import { WrapperTable } from 'components/tableP/table/TablePrincipal.style';

import {
  ContainerTable,
  HeaderTable,
  WrapperActions,
  WrapperSearch,
} from './TeamList.styles';

import {
  imgGoBackPurple,
  imgPeopleSearch,
  imgSuccess,
  imgError,
  editIcon,
  iconInfo,
} from 'image';
import { useToast } from 'hook/useToast';
import Pagination from 'components/tableP/pagination/Pagination';
import { headingsTable } from './headingsTable';
import { type ITeams } from 'store/slice/interface';
import { HabiModal } from '@habitech/habi-modal';
import { Search } from '@habitech/keychain-atoms-inputs';
import { deleteTeam } from 'service/teams/teams.api';
import { Helmet } from 'react-helmet';
import GenericTable from 'components/GenericTable/GenericTable';
import { getLocalStorageItem } from 'utils/funcionHelper';
import { ButtonDelete } from 'components/buttonDelete/ButtonDelete';
import { LigthText } from 'components/formRegister/FormRegister.styles';

const TeamList = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { save } = useParams();
  const { isLoading } = useSelector(selectIsLoading);
  const { teams } = useSelector(selectTeams);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [newPage, setNewPage] = useState<number | null>(null);
  const [currentList, setCurrentList] = useState<ITeams[]>([]);
  const [sortType, setSortType] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [totalLength, setTotalLength] = useState<number>(0);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [filteredTeams, setFilteredTeams] = useState<ITeams[]>([]);
  const totalPages = Math.ceil(totalLength / currentLimit);
  const [newTeams, setNewTeams] = useState([]);
  const { role } = getLocalStorageItem('TOKEN');

  const { setTeamsList } = useTeams();

  const routerHome = () => {
    navigate(ROUTER_URL.ACTIONS);
  };

  const onLimitChange = (newLimit: number) => {
    setCurrentLimit(newLimit);
    const newTotalPages =
      filteredTeams?.length > 0
        ? Math.ceil(filteredTeams?.length / newLimit)
        : Math.ceil(newTeams?.length / newLimit);
    if (currentPage > newTotalPages) {
      setNewPage(newTotalPages);
    }
  };

  const MODAL_SUBTITLE = {
    subtitle: (
      <>
        <LigthText>Al eliminar, toda la información del equipo se perderá, todos los sellers y zonas medianas de este quedarán liberados.</LigthText>
      </>
    ),
  };

  const handleDeleteTeam = (id: number) => {
    setTeamToDelete(id);
    setDeleteModal(true);
  };

  const generateListTable = () => {
    const newTeamsA: any = [];
    if (teams.length > 0) {
      teams.forEach((team) => {
        newTeamsA.push({
          ...team,
          label: team.label || '_',
          mex_state: team.mex_state || '_',
          actions: (
            <div className='actions'>
              {role !== 'equipo_comercial' ? (
                <>
                  <Button
                    dataId='test_id'
                    variant='ghost'
                    onClick={() => {
                      navigate(`/equipos/editar/${team.id}`);
                    }}
                  >
                    <img
                      src={editIcon}
                      style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                  <ButtonDelete
                    onClick={() => {
                      handleDeleteTeam(team.id);
                    }}
                  />
                </>
              ) : (
                <img
                  src={iconInfo}
                  alt=''
                  width={24}
                  height={24}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/equipos/editar/${team.id}`);
                  }}
                />
              )}
            </div>
          ),
        });
      });
      setNewTeams(newTeamsA);
    }
  };

  const deleteTeamClick = async() => {
    setDeleted(true);

    try {
      await deleteTeam(+teamToDelete);
      showToast({
        title: 'Equipo eliminado',
        subtitle: 'El equipo se eliminó correctamente',
        icon: imgSuccess,
        type: '',
      });
    } catch (error) {
      console.error(error);
      showToast({
        title: 'No se pudo eliminar el equipo',
        subtitle:
        'El equipo no se pudo eliminar debido a un error en el servicio. Inténtalo más tarde',
        icon: imgError,
        type: '',
      });
    } finally {
      setDeleted(false);
      setDeleteModal(false);
      setTeamsList();
      setFilteredTeams([]);
    }
  }

  useEffect(() => {
    generateListTable();
  }, [teams]);

  const validateListValues = (list: ITeams[]) => {
    return list;
  };

  const startSearch = document.querySelector(
    '[data-id=searchByEmail-icon-search]'
  ) as HTMLElement;
  const inputSearch = document.querySelector(
    '[data-id=searchByEmail]'
  ) as HTMLInputElement;

  const filterByTeamName = (value: string) => {
    setLoadingSearch(true);
    setTimeout(() => {
      const data = newTeams?.filter((item: any) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      const newStartIndex = (currentPage - 1) * currentLimit;
      const newEndIndex = newStartIndex + currentLimit;
      const parsedData = validateListValues(data);
      const newTeamsParced = parsedData?.slice(newStartIndex, newEndIndex);
      setCurrentList(newTeamsParced);
      setFilteredTeams(parsedData);
      setLoadingSearch(false);
      setTotalLength(data.length);
      if(data.length === 0) return false;
      setCurrentPage(1);

    }, 300);
  };

  const handleClick = useCallback(() => {
    filterByTeamName(inputSearch?.value ?? '');
  }, [filterByTeamName, inputSearch?.value]);

  useEffect(() => {
    startSearch?.addEventListener('click', handleClick);

    return () => {
      startSearch?.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const emptyValueSearch = (value: string) => {
    if (value === '') {
      setLoadingSearch(true);
      setTimeout(() => {
        setTotalLength(newTeams?.length);
        setLoadingSearch(false);
        generateListTable();
        setFilteredTeams([]);
      }, 300);
    }
  };

  useEffect(() => {
    if (newPage !== null) {
      setCurrentPage(newPage);
      setNewPage(null);
    } else {
      const newStartIndex = (currentPage - 1) * currentLimit;
      const newEndIndex = newStartIndex + currentLimit;
      const tempTeams = [...newTeams];

      const tempList = validateListValues(tempTeams);
      
      const newTeamsParced = filteredTeams.length
        ? filteredTeams?.slice(newStartIndex, newEndIndex)
        : tempList?.slice(newStartIndex, newEndIndex);

      setCurrentList(newTeamsParced);
    }
  }, [newTeams, currentPage, currentLimit, newPage]);

  useEffect(() => {
    setTotalLength(newTeams?.length);
  }, [newTeams]);

  useEffect(() => {
    let title = '';
    let subtitle = '';
    setTeamsList();

    if (save === 'save') {
      title = 'Equipo creado';
      subtitle = 'La información se ha guardado con éxito.';
    }

    if (save === 'update') {
      title = 'Equipo actualizado';
      subtitle = 'La información se ha actualizado con éxito.';
    }

    if (save) {
      showToast({
        title,
        subtitle,
        icon: imgSuccess,
        type: '',
      });
    }

    const sort = { ...sortType };
    headingsTable.map((item: any) => {
      if (item.id !== 'name') {
        sort[item.id] = null;
      } else {
        sort[item.id] = false;
      }
      return true;
    });
    setSortType(sort);
  }, []);

  return (
    <ContainerTable>
      <Helmet>
        <title>Administrar Equipos</title>
      </Helmet>
      <WrapperTable>
        <div className='container-return'>
          <Button
            dataId='btn-redirect-home'
            variant='ghost'
            iconLeft={
              <img src={imgGoBackPurple} alt='regresar a home' width={36} />
            }
            onClick={routerHome}
          >
            Regresar
          </Button>
        </div>
        <HeaderTable>
          <div className='title-teams'>Equipos</div>
          <WrapperActions>
            {role !== 'equipo_comercial' && (
              <Button
                dataId='test-id'
                onClick={() => {
                  navigate('/equipos/crear');
                }}
                size='large'
              >
                Crear equipo
              </Button>
            )}
            <WrapperSearch>
              <Search
                dataId='searchByEmail'
                name='search_email'
                placeHolder='Buscar por nombre'
                loading={loadingSearch}
                onChange={(name, val) => {
                  emptyValueSearch(val);
                }}
              />
            </WrapperSearch>
          </WrapperActions>
        </HeaderTable>

        <div>
          {isLoading ? (
            <>
              {currentList.length === 0 ? (
                <div className='no-result-img'>
                  <p className='title-no-result'>Sin resultados</p>
                  <img
                    className='imagen-no-result'
                    src={imgPeopleSearch}
                    alt='No hay registros'
                  />
                </div>
              ) : (
                <GenericTable
                  defaultColumns={headingsTable}
                  data={currentList}
                />
              )}
            </>
          ) : (
            <div className='loading-table'>
              <Loader />
            </div>
          )}

          {currentList.length > 0 && isLoading && (
            <Pagination
              limitOptions={[10, 20]}
              totalPages={totalPages}
              currentLimit={currentLimit}
              setCurrentLimit={setCurrentLimit}
              onLimitChange={onLimitChange}
              onPageChange={() => {}}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalLength={totalLength}
            />
          )}
        </div>
      </WrapperTable>

      <HabiModal
        dataId='delete-team'
        closeOnClickOutside
        subtitle={MODAL_SUBTITLE.subtitle as unknown as string}
        onClickPrimary={deleteTeamClick}
        onClickSecondary={() => {
          setDeleteModal(false);
        }}
        onClose={() => {
          setDeleteModal(false);
        }}
        primaryBtn={'Sí, eliminar'}
        disabledPrimaryButton={deleted}
        secondaryBtn={'Cancelar'}
        title='¿Quieres eliminar este equipo?'
        open={deleteModal}
      />
    </ContainerTable>
  );
};

export default TeamList;
