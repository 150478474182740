import {
  CheckOption,
  ContainerSelectors,
  Option,
  SubTitle,
  Title,
  ZoneOptions,
} from './SelectionMultipleOptions.styles';
import { checkZonesBlue, checkZonesGreen } from 'image';
import type { SelectorOptionsProps } from './model';
import { CenteredDiv } from '../cardForm/CardForm.styles';
import { Loader } from '@habitech/loader';
import Tooltip from './TooltipContainer';

const SelectionMultipleOptions = ({
  title,
  description,
  options,
  setOptions,
  loading,
  type,
  dataLoaded
}: SelectorOptionsProps) => {

  const handleOption = (option: any) => {
    const newOptions: any = [];
    options.forEach((noption) => {
      if (noption.id === option.id) {
        newOptions.push({ ...option, selected: !option.selected });
      } else {
        newOptions.push({ ...noption });
      }
    });
    setOptions(newOptions);
  };

  const getOptions = (type: string) => {
    return options?.map((option) => {
      const { label, id, selected } = option;
      const new_label = label?.length > 16 ? `${label.slice(0, 16)}...` : label;
      return (
        <Tooltip key={`${id}-${new Date().getTime()}`} text={label}>
          <Option
            type={type}
            selected={selected}
            onClick={() => {
              handleOption(option);
            }}
          >
            <div>{new_label}</div>
            <CheckOption selected={selected}>
              {selected && <img src={type === 'primary' ? checkZonesBlue : checkZonesGreen} />}
            </CheckOption>
          </Option>
        </Tooltip>
      );
    });
  };

  return (
    <ContainerSelectors type={type}>
      <Title>{title}</Title>
      <SubTitle>
        <div>{description}</div>
        {options.length > 0 && dataLoaded && (
          <div className='indicator'>
            {options.filter((opt) => opt.selected === true).length}/
            {options.length}
          </div>
        )}
      </SubTitle>
      { dataLoaded &&
        <>
          {loading && (
            <CenteredDiv style={{ gridColumn: '1/1' }}>
              <Loader />
            </CenteredDiv>
          )}
          {options.length > 0 && !loading && (
            <ZoneOptions>{getOptions(type)}</ZoneOptions>
          )}
        </>
      }
    </ContainerSelectors>
  );
};
export default SelectionMultipleOptions;
