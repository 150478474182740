import { ResponseSeller, ResponseSellerLogin } from 'service/api';
export const getZoneMatures = async ({ pagination, offset }: any) => {
  return await ResponseSeller.get('/zones-matures', {
    params: { pagination, offset },
  });
};

export const getMedianZoneByID = async ({
  country,
  city_id,
  pagination,
}: any) => {
  return await ResponseSeller.get('/median-zones', {
    params: { country, city_id, pagination },
  });
};
export const getCities = async ({ country }: any) => {
  return await ResponseSeller.get('/cities', {
    params: { country },
  });
};
export const getSellerByZone = async ({ country, zone_median_id }: any) => {
  return await ResponseSeller.get('/median-zones/sellers', {
    params: { country, zone_median_id },
  });
};
export const getSellerByCityId = async ({ country, city_id }: any) => {
  return await ResponseSeller.get(`/cities/${city_id}/sellers`, {
    params: { country },
  });
};
export const saveMatureZone = async (params: any) => {
  return await ResponseSeller.post('/zones-matures', params);
};

export const getMatureZoneByID = async (mature_zone_id: number) => {
  return await ResponseSeller.get(`/zones-matures/${mature_zone_id}`);
};

export const updateMatureZoneByID = async (
  mature_zone_id: number,
  params: any
) => {
  return await ResponseSeller.put(`/zones-matures/${mature_zone_id}`, params);
};
export const updateSellerMedianZone = async (
  median_zone_id: number,
  params: any
) => {
  return await ResponseSellerLogin.put(
    `/median-zones/${median_zone_id}/sellers`,
    params
  );
};
