import styled from 'styled-components';

import {
  electricPurple25,
  electricPurple600,
  scarpaGray100,
  scarpaGray500,
  scarpaGray600,
  white,
  robotoRegular
} from '@habitech/shared';

import { icoChevronDown } from 'image';

interface IpropsColums {
  padding?: string;
  width?: string;
  alignItems?: string;
}

export const PaginationWrapper = styled.section.attrs({
  className: 'PaginationWrapper',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin: 16px 0;
`;

export const ColumnPagination = styled.article.attrs({
  className: 'ColumnPagination',
})<IpropsColums>`
  width: ${props => props.width || ''};
  padding-right: ${(props) => props.padding || ''};
  display: flex;
  justify-content: end;
  align-items: ${props => props.alignItems || ''};
  gap: 10px;
  font-family: ${robotoRegular};
`

export const PageButton = styled.button.attrs({
  className: 'PageButton',
})<{ active?: boolean }>`
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  font-size: 12px;
  border-radius: 8px;
  border: 1px solid ${scarpaGray100};
  background: #FFF;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    border-color: ${scarpaGray100};
    color: ${scarpaGray600};
    cursor: not-allowed;
  }
  ${ props => props.active && `
    border-color: ${electricPurple600};
    background-color: ${electricPurple25};
    color: ${electricPurple600};
  `};
`;

export const TextPagination = styled.p.attrs({
  className: 'TextPagination',
})`
  color: ${scarpaGray500};
  font-size: 14px;
`

export const LimitSelect = styled.select.attrs({
  className: 'LimitSelect',
})`
  font-size: 14px;
  appearance: none;
  cursor: pointer;
  height: 40px;
  padding: 8px 35px 8px 15px;
  border-radius: 4px;
  color: ${scarpaGray600};
  background-color: ${white};
  border: 1px solid ${scarpaGray100};
  background-image: url(${icoChevronDown});
  background-repeat: no-repeat;
  background-position: right 10px center;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(0, 128, 255, 0.3);
  }
`;
