import styled from "styled-components";

export const StyledButtonDelete = styled.button.attrs({
  className: "StyledButtonDelete",
})`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  display: grid;
  place-items: center;

  img {
    filter: grayscale(100%) brightness(100%);
    opacity: 0.85;
  }

  &:hover {
    img {
      filter: grayscale(0%) brightness(100%);
      opacity: 1;
    }
  }
`