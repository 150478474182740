import { ResponseSeller, ResponseSellerLogin, ResponseSellerMX } from 'service/api';

export const authUser = async (GoogleCredential: string, country: string) => {
  if (country === "CO") {
    return await ResponseSellerLogin.post('/auth', { GoogleCredential, country });
  } else {
    return await ResponseSellerMX.post('/auth', { GoogleCredential, country });
  }
};
export const authFresh = async (token: string, userName: string) => {
  return await ResponseSeller.post('/auth', {
    RefreshToken: token,
    userName,
  });
};
