import styled from 'styled-components';
import {
  monserratRegular,
  robotoRegular,
  turquoise25,
  turquoise600,
  scarpaGray500,
  error,
  tomatoCoral25,
  size,
} from '@habitech/shared';

import { type IToastStyle } from "./models";

export const Wrapper = styled.div<IToastStyle>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 160px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: calc(100% - 16px - 16px);
  background-color: ${turquoise25};
  background-color: ${({ type }) => type === 'warning' ? tomatoCoral25 : turquoise25};
  border-radius: 6px;
  z-index: 99999;

  & > .wp-data {
    display: flex;
    gap: 18px;

    & > .icon_toast {
      background: ${({ type }) => type === 'warning' ? error : turquoise600};
      display: grid;
      place-items: center;
      border-radius: 6px 0 0 6px;
      padding: 0 4px;
      & > img {
        width: 30px;
        display: block;
        margin: auto;
      }
    }

    & > .info_toast {
      padding: 16px 0;
      .title_toast {
        font-size: 14px;
        font-weight: 600;
        font-family: ${monserratRegular};
        margin: 0 0 4px;
      }

      .subtitle_toast {
        font-size: 12px;
        margin: 0;
        font-family: ${robotoRegular};
        color: ${scarpaGray500};
      }
    }
  }

  & > .closed_toast {
    & > button {
      padding: 0;
      & > .image_toast_button {
        width: 32px;
        height: 32px;
      }
    }
  }

  @media (min-width: ${size.tablet.min}) {
    min-width: 359px;
    max-width: fit-content;
    transform: translateX(0%);
    left: auto;
    margin-top: 48px;
    right: 24px;
  }
`;
