import { useState } from 'react';
import { getSearchedZones } from 'service/teams/teams.api';

import {
  type valProp,
  type IListOptions,
} from '@habitech/call-to-action/src/lib/dropdown-button/models/dropdown-button.model';

import {
  type IOption,
  type IUseTeamManageForm,
} from 'pages/teamManage/interface';
import { removeSpecialCharactersAndSpaces } from 'utils/funcionHelper';
import { useAppDispatch } from 'store/store';
import { setIsLoading } from 'store/slice/userSlice';

export const useTeamManageForm = ({
  selectedData,
  setSelectedData,
}: IUseTeamManageForm) => {
  const dispatch = useAppDispatch();

  const [zones, setZones] = useState([]);
  const [zonesInformation, setZonesInformation] = useState([]);
  const [errorZone, setErrorZone] = useState(false);
  const [errorZoneAvailable, setErrorZoneAvailable] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [selectedZones, setSelectedZones] = useState(selectedData.median_zones);
  const [sellersInformation, setSellersInformation] = useState<IOption[]>([]);
  const [selectedSellers, setSelectedSellers] = useState<IOption[]>([]);
  const [sellers, setSellers] = useState<IOption[]>([]);
  const [errorLabel, setErrorLabel] = useState(false);
  const [validateModel, setValidateModel] = useState<boolean>(false);
  const [modalCounter, setModalCounter] = useState<boolean>(false);

  const sessionCounter = localStorage.getItem('model_assignation');

  const convertToOptions = (label: string, value: string, array: any) => {
    const options = array.map((item: any) => {
      return { label: item[label], value: item[value] };
    });

    return options;
  };

  const handleSearchZone = async (search: string) => {
    if (search.length < 3) return;
    setLoaded(true);
    setErrorZone(false);
    try {
      const response = await getSearchedZones(search);
      if (response.status === 200) {
        const allZones = response.data.data;
        setZonesInformation(allZones);
        const options = convertToOptions('label', 'id', allZones);
        setZones(options);
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      setLoaded(false);
      setErrorZone(true);
      setErrorZoneAvailable(false);
      setZones([]);
      setTimeout(() => {
        const element = document.querySelector('[data-id="zone-search-list"]');
        if (element !== null) {
          element.innerHTML = '';
        }
      }, 100);
    } finally {
      setLoaded(false);
    }
  };

  const handleSelectZone = (item: IListOptions) => {
    const tempZones: IOption[] = [...zones];
    const tempZonesInformation: any = [...zonesInformation];
    const zoneInformation = tempZonesInformation.find(
      (zone: any) => zone.id === Number(item.value)
    );
    const zoneFound = tempZones.find((zone: any) => zone.value === item.value);
    if (zoneFound) {
      const list = [...selectedZones];
      const isFind = list.find((zone: any) => zone.id === item.value);
      if (!isFind) {
        list.push(zoneFound);
        const temp = { ...selectedData };
        temp.median_zones.push(zoneInformation);
        setSelectedData(temp);
      }
      setSelectedZones(list);
    }
  };

  const deleteZone = (value: string) => {
    const tempZones = [...selectedZones];
    const tempZonesInformation = [...selectedData.median_zones];
    const filteredZones = tempZones.filter((item) => item.id !== value);
    const filteredZonesInformation = tempZonesInformation.filter(
      (item) => item.id !== Number(value)
    );
    setSelectedZones(filteredZones);
    setSelectedData({
      ...selectedData,
      median_zones: filteredZonesInformation,
    });

    dispatch(setIsLoading(false));

    setTimeout(() => {
      dispatch(setIsLoading(true));
    }, 200);
  };

  const handleChangeSeller = (value: string) => {
    const tempSellers: any = [...sellersInformation];
    const filteredSellers = tempSellers.filter((item: any) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    const options = convertToOptions('name', 'id', filteredSellers);
    setSellers(options);
  };

  const handleSelectSeller = (item: IListOptions) => {
    const tempSellers: any = [...sellersInformation];
    const sellerFound = tempSellers.find(
      (seller: any) => seller.id === item.value
    );
    if (sellerFound) {
      const list: IOption[] = [...selectedSellers];
      const isFind = list.find((zone: any) => zone.value === item.value);
      if (!isFind) {
        list.push({ label: sellerFound.name, value: sellerFound.id });
        const temp = { ...selectedData };
        temp.sellers.push({ label: sellerFound.name, value: sellerFound.id });
        setSelectedData(temp);
      }
      setSelectedSellers(list);
    }
  };

  const deleteSeller = (value: string) => {
    const tempSelectedSellers = [...selectedSellers];
    const tempSellersInformation = [...selectedData.sellers];
    const filteredSellers = tempSelectedSellers.filter(
      (item) => item.value !== value
    );
    const filteredSellersInformation = tempSellersInformation.filter(
      (item) => item.value !== Number(value)
    );
    setSelectedSellers(filteredSellers);
    const tempSelectedData = { ...selectedData };
    tempSelectedData.sellers = filteredSellersInformation;
    setSelectedData(tempSelectedData);
  };

  const handleChangeLeader = (value: valProp) => {
    const temp = { ...selectedData };
    temp.leader_id = Number(value);
    setSelectedData(temp);
  };

  const handleChangeTeamLabel = (value: string, input: string, id?: string) => {
    const temp: any = { ...selectedData };
    if (input === 'name') {
      const regex = /^[a-zA-Z _]+$/;
      if (regex.test(value) && value.length <= 45) {
        temp[input] = value;
      }
      if (value.length === 0) {
        temp[input] = '';
      }
      if (value.length < 5) {
        setErrorLabel(true);
      }
      if (value.length >= 5) {
        setErrorLabel(false);
      }
    } else {
      if (value.length <= 30) temp[input] = value;
    }

    if (id) {
      setSelectedData(temp);
    } else {
      setSelectedData({
        ...temp,
        label: removeSpecialCharactersAndSpaces(value),
      });
    }
  };

  const handleSelectModel = (value: valProp) => {
    const temp = { ...selectedData };
    temp.model_assignation = String(value);
    setSelectedData(temp);
    setValidateModel(true);
    if( sessionCounter === "1") {
      setModalCounter(true);
    }
  };

  const onCloseModalModelAssignation = () => {
    setModalCounter(false);
    localStorage.setItem('model_assignation', '0');
  }

  return {
    // Estados
    zones,
    errorZone,
    errorZoneAvailable,
    loaded,
    sellers,
    errorLabel,
    selectedZones,
    selectedSellers,
    validateModel,
    modalCounter,
    // Metodos
    setSelectedZones,
    setSelectedSellers,
    setSellers,
    setValidateModel,
    setSellersInformation,
    convertToOptions,
    handleSearchZone,
    handleSelectZone,
    deleteZone,
    handleChangeSeller,
    handleSelectSeller,
    deleteSeller,
    handleChangeLeader,
    handleChangeTeamLabel,
    handleSelectModel,
    setErrorZoneAvailable,
    onCloseModalModelAssignation
  };
};
