import styled from 'styled-components';
import { ZoneOptions } from './SelectionMultipleOptions.styles';

interface IContainerButton {
  widthButtons?: string;
}

export const ContainerZones = styled(ZoneOptions)``;

export const ContainerButton = styled.div<IContainerButton>`
  width: ${(props) => (props.widthButtons ? props.widthButtons : '110px')};
`;

export const ButtonSellerAssign = styled.div<IContainerButton>`
  width: ${(props) => (props.widthButtons ? props.widthButtons : '110px')};
`;
