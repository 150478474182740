import React from 'react';
// import { Button } from '@habitech/call-to-action';
import { ContainerZones, ButtonSellerAssign } from './ButtonContainer.styles';
import Tooltip from './TooltipContainer';
import { icoCloseModal } from 'image';
import { TEXT_LABEL_ASSIGN_SELLER } from '../cardForm/CardForm.utils';

interface ButtonProps {
  button: any;
  onSelect: (id: number) => void;
  widthButtons?: string;
  characters?: number;
  withId?: boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({
  button,
  onSelect,
  widthButtons,
  characters = 8,
  withId = false,
}) => {
  const handleButtonClick = () => {
    onSelect(button.id);
  };

  const capitalize = (text: string = '') => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <ButtonSellerAssign widthButtons={widthButtons}>
      <Tooltip
        text={
          button?.label.length > characters ? capitalize(button?.label) : ''
        }
      >
        <div className='button-assigned' onClick={handleButtonClick}>
          <p>
            {withId && `${button?.id}-`}
            {capitalize(button?.label).slice(0, characters)}
            {button?.label.length > characters && '...'}
          </p>
          <button>
            <img src={icoCloseModal} alt='' />
          </button>
        </div>
      </Tooltip>
    </ButtonSellerAssign>
  );
};

interface ButtonContainerProps {
  buttons: any;
  setButtons: any;
  buttonsTwo: any;
  setButtonsTwo: any;
  widthButtons?: string;
  characters?: number;
  withId?: boolean;
  setValidUpdate?: any;
  setSellersAssigned?: any;
}

const ContainerAssignSeller: React.FC<ButtonContainerProps> = ({
  buttons,
  setButtons,
  buttonsTwo,
  setButtonsTwo,
  widthButtons,
  characters,
  withId,
  setValidUpdate,
  setSellersAssigned,
}) => {
  const handleMakeAvailable = (id: number) => {
    const updatedButtons = buttons.filter((el: any) => el.id !== id);
    const filteredSeller = buttons.filter((el: any) => el.id === id);
    setButtons(updatedButtons);
    setButtonsTwo(buttonsTwo.concat(filteredSeller));
    setValidUpdate(true);
    if (updatedButtons.length === 0) {
      setSellersAssigned(TEXT_LABEL_ASSIGN_SELLER.unselected);
    } else {
      setSellersAssigned(TEXT_LABEL_ASSIGN_SELLER.selected);
    }
  };
  return (
    <ContainerZones>
      {buttons.map((button: { id: React.Key | null | undefined }) => (
        <ButtonComponent
          key={button.id}
          button={button}
          onSelect={handleMakeAvailable}
          widthButtons={widthButtons}
          characters={characters}
          withId={withId}
        />
      ))}
    </ContainerZones>
  );
};

export default ContainerAssignSeller;
