import { useState, useEffect } from 'react';
import { ROUTER_URL } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'hook/useToast';
import { imgSuccess } from 'image';
import { removeSpecialCharactersAndSpaces } from 'utils/funcionHelper';
import {
  getCities,
  getMedianZoneByID,
  saveMatureZone,
  getMatureZoneByID,
  updateMatureZoneByID,
} from 'service/mature/mature.api';

const useCartFormMature = () => {
  const navigator = useNavigate();
  const { showToast } = useToast();
  const [options, setOptions] = useState<any[]>([]);
  const [idMatureZone, setIdMatureZone] = useState<number | undefined>();
  const [zonesSelected, setZonesSelected] = useState<any[]>([]);
  const [zonesEditSelected, setZonesEditSelected] = useState<number[]>([]);
  const [loader, setLoader] = useState(false);
  const [loaderTwo, setLoaderTwo] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [validData, setValidData] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({
    id: 0,
    label: '',
    name: '',
    active: 0,
    city_label: '',
    city_id: 0,
    median_zones: [],
  });
  const [active, setActive] = useState<string | any>();
  const [cities, setCities] = useState([{ value: '1', label: 'Bogotá' }]);
  const [cityId, setCityId] = useState<string | any>();
  const [label, setLabel] = useState('');
  const routerMaturesHome = () => {
    navigator(ROUTER_URL.MATURES_ZONE);
  };
  useEffect(() => {
    handleGetIdZones();
    setShowMap(false);
    setTimeout(() => {
      setShowMap(true);
    }, 10);
  }, [options]);
  const isEdit =
    location.pathname.slice(0, 19) === ROUTER_URL.MATURES_ZONE_EDIT;

  const handleGetMatureZoneId = (): number | undefined => {
    const numberMatch = location.pathname.match(/\/(\d+)$/);
    return numberMatch ? parseInt(numberMatch[1]) : undefined;
  };
  useEffect(() => {
    if (isEdit) {
      setIdMatureZone(handleGetMatureZoneId());
    }
    handleCallCities();
  }, []);

  useEffect(() => {
    if (isEdit && idMatureZone) {
      handleGetDataToEdit();
    }
  }, [idMatureZone]);

  useEffect(() => {
    if (cityId) {
      handleGetMedianZone();
    }
  }, [cityId]);

  useEffect(() => {
    isValidData();
  }, [cityId, zonesSelected, label, active]);

  const handleGetDataToEdit = async () => {
    try {
      if (idMatureZone) {
        const { data } = await getMatureZoneByID(idMatureZone);
        setDataToEdit(data.data);
        const newData = data.data.median_zones.map(
          (item: { id: number }) => item.id
        );
        setCityId(data.data.city_id);
        setZonesEditSelected(newData);
        setActive(data.data.active.toString());
        setLabel(data.data.label);
        handleGetMedianZone();
      }
    } catch (error) {}
  };

  const handleGetMedianZone = async () => {
    setLoaderTwo(true);

    if (cityId) {
      try {
        let pagination = 0;
        let calls;
        const newZonas: any[] = [];
        do {
          const response = await getMedianZoneByID({
            country: 'CO',
            city_id: cityId,
            pagination,
          });
          if (!calls) {
            calls = +response.data.data.total_pages;
          }
          if (calls >= 0) {
            pagination += 75;
          }
          calls -= 1;
          newZonas.push(...response.data.data.median_zone);
        } while (calls >= 1);

        const newData = newZonas.map(
          (item: { id: any; label: any; poligono: any }) => ({
            id: item.id,
            label: item.label || 'No/name',
            selected: isEdit ? zonesEditSelected.includes(item.id) : false,
            coordinates: item.poligono,
          })
        );

        setOptions(newData);

        setLoaderTwo(false);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
  };
  const handleCallCities = async () => {
    setLoader(true);
    try {
      const { data } = await getCities({
        country: 'CO',
      });
      const newData = data.data.map((item: { id: string; label: string }) => {
        return { value: item.id, label: item.label };
      });
      setCities(newData);
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrintMap = () => {
    const selected = options.filter(
      (button: { selected: any }) => button.selected
    );
    return selected;
  };
  const handleGetIdZones = () => {
    const selected = options.filter(
      (button: { selected: any }) => button.selected
    );
    setZonesSelected(selected.map((item) => item.id));
  };

  const isValidData = () => {
    setValidData(
      Boolean(
        cityId && label && Number(active) >= 0 && zonesSelected.length >= 1
      )
    );
  };

  const handleSaveData = async () => {
    const dataToSave = {
      country: 'CO',
      city_id: cityId,
      median_zone_ids: zonesSelected,
      label,
      name: removeSpecialCharactersAndSpaces(label),
      active: Number(active),
    };
    setValidData(false);
    try {
      await saveMatureZone(dataToSave);
      showToast({
        title: 'Registrado',
        subtitle: 'Se ha registrado correctamente',
        icon: imgSuccess,
        type: '',
      });
      routerMaturesHome();
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdateData = async () => {
    const dataToSave = {
      country: 'CO',
      city_id: cityId,
      median_zone_ids: zonesSelected,
      label,
      name: removeSpecialCharactersAndSpaces(label),
      active: Number(active),
    };
    setValidData(false);
    try {
      if (idMatureZone) {
        await updateMatureZoneByID(idMatureZone, dataToSave);
      }
      showToast({
        title: 'Actualizado',
        subtitle: 'Se ha actualizado correctamente',
        icon: imgSuccess,
        type: '',
      });
      routerMaturesHome();
    } catch (error) {
      console.error(error);
    }
  };
  return {
    active,
    loader,
    loaderTwo,
    isEdit,
    cities,
    dataToEdit,
    options,
    validData,
    label,
    showMap,
    setCityId,
    setOptions,
    routerMaturesHome,
    handleChangeLabel,
    removeSpecialCharactersAndSpaces,
    setActive,
    handleUpdateData,
    handleSaveData,
    handlePrintMap,
  };
};

export default useCartFormMature;
