import styled from 'styled-components';

import { monserratRegular, scarpaGray800 } from '@habitech/shared';

export const ContainerTable = styled.article.attrs({
  className: 'ContainerTable',
})`
  h1 {
    font-family: ${monserratRegular};
    color: ${scarpaGray800};
    margin: 0;
  }
`;

export const HeaderTable = styled.header.attrs({
  className: 'HeaderTable',
})`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .title-teams {
    color: ${scarpaGray800};
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const WrapperActions = styled.div.attrs({
  className: 'WrapperActions',
})`
  display: flex;
  align-items: center;
`;

export const WrapperSearch = styled.div.attrs({
  className: 'WrapperSearch',
})`
  min-width: 324px;
  margin-left: 32px;
`;
