import {
  robotoRegular,
  scarpaGray500,
  skyBlue100,
  skyBlue300,
  skyBlue400,
  skyBlue50,
  skyBlue600,
  turquoise50,
  turquoise100,
  turquoise400,
  turquoise600,
  white,
} from '@habitech/shared';
import styled from 'styled-components';

interface PropsHandleColors {
  type: string;
}

const ContainerSelectors = styled.div.attrs({
  className: 'ContainerSelectors',
})<PropsHandleColors>`
  padding: 12px;
  border-radius: 8px;
  display: grid;
  margin-bottom: 16px;
  ${(props) => {
    switch (props.type) {
      case 'primary':
        return `
          background-color: ${skyBlue50};
          .Title {
            color: ${skyBlue600};
          }
          .ZoneOptions {
            &::-webkit-scrollbar {
              background-color: ${skyBlue100};
            }
            &::-webkit-scrollbar-thumb {
              background-color: ${skyBlue300};
            }
          }
        `;
      case 'secondary':
        return `
          background-color: ${turquoise50};
          .Title {
            color: ${turquoise600};
          }
          .ZoneOptions {
            &::-webkit-scrollbar {
              background-color: ${turquoise100};
            }
            &::-webkit-scrollbar-thumb {
              background-color: ${turquoise600};
            }
          }
        `;
      default:
        return ``;
    }
  }}
`;

const Title = styled.div.attrs({
  className: 'Title',
})`
  font-size: 14px;
  font-family: ${robotoRegular};
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 8px;
`;
const SubTitle = styled.div.attrs({
  className: 'SubTitle',
})`
  font-size: 12px;
  font-family: ${robotoRegular};
  color: ${scarpaGray500};
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 70% 30%;
  .indicator {
    justify-content: right;
    display: grid;
  }
`;

const ZoneOptions = styled.div.attrs({
  className: 'ZoneOptions',
})`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px 16px;
  max-height: 142px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;

const Option = styled.div.attrs({
  className: 'Option',
})<OptionProps>`
  display: grid;
  padding: 4px 4px 4px 12px;
  box-shadow: 0px 2px 8px 0px #0000001a;
  border-radius: 16px;
  font-size: 12px;
  font-family: ${robotoRegular};
  color: ${scarpaGray500};
  line-height: 14.06px;
  font-weight: 400;
  text-transform: capitalize;
  align-items: center;
  grid-template-columns: auto 1fr;
  height: 32px;
  ${(props) => {
    switch (props.type) {
      case 'primary':
        return {
          backgroundColor: props.selected ? skyBlue400 : white,
        };
      case 'secondary':
        return {
          backgroundColor: props.selected ? turquoise400 : white,
        };
      default:
        return {};
    }
  }}

  &:hover {
    cursor: pointer;
  }
`;

interface OptionProps {
  selected: boolean;
  type?: string;
}

const CheckOption = styled.div.attrs({
  className: 'CheckOption',
})<OptionProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${(props) =>
    props.selected ? '2px solid transparent' : `2px solid #f7f9fc`};
  background-color: ${white};
  justify-self: right;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export {
  ContainerSelectors,
  Title,
  SubTitle,
  ZoneOptions,
  Option,
  CheckOption,
};
