import { imgWelcome } from 'image';
import { Container, Image, Wrapper } from './Login.style';
import LoginGoogle, { type IUserGoogle } from './loginGoogle';
import useLogin from './useLogin';
export interface IUserAuth extends IUserGoogle {
  isAuth?: boolean;
}

const Login = () => {
  const { onLoginSuccess, handleGoogleError } = useLogin();
  return (
    <Container data-testid='container-login'>
      <Wrapper>
        <Image src={imgWelcome} alt='Login Image' />
        <LoginGoogle onError={handleGoogleError} onNextStep={onLoginSuccess} />
      </Wrapper>
    </Container>
  );
};

export default Login;
