import { createSlice } from "@reduxjs/toolkit";
import { type IPropsTeamsSlice } from "./interface";
import { type RootState } from "../store";

const initialState:IPropsTeamsSlice = {
  teams:[],
}

export const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
  },
})

export const { setTeams } = teamSlice.actions;

export const selectTeams = (state: RootState) => state.teams;

export default teamSlice.reducer;