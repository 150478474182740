import styled from 'styled-components';

import { robotoRegular, disabledBorder, scarpaGray200 } from '@habitech/shared';

export const Label = styled.div`
  display: flex;
  font-family: ${robotoRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgb(37, 33, 41);
  padding: 0px 0px 0px 4px;
  margin-bottom: 4px;
`;

export const InputFake = styled.div`
  border-radius: 6px;
  border: 1px solid ${disabledBorder};
  background: #f3f3f3;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  position: relative;
  -webkit-box-align: center;

  .internal {
    height: 48px;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .model-name {
      color: ${disabledBorder};
      font-family: ${robotoRegular};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .locked-counter {
      display: flex;
      height: 32px;
      padding: 0 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: ${scarpaGray200};
      .text {
        color: var(--font-habi-pcom-main-aaa, #252129);
        font-family: ${robotoRegular};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }
  }
`;
export const ContainerInputFake = styled.div`
  width: 100%;
`;
