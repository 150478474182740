import React from 'react';
import { ContainerButtonsZones } from './ButtonZones.styles';
import { icoCloseModal } from 'image';
import Tooltip from 'pages/maturesZones/components/SelectionMultipleOptions/TooltipContainer';
import { getLocalStorageItem, capitalizeWords } from 'utils/funcionHelper';

interface ButtonProps {
  button: any;
  onSelect: (id: number) => void;
  characters?: number;
  capitalize?: boolean;
}
const ButtonZones: React.FC<ButtonProps> = ({
  button,
  onSelect,
  characters = 14,
  capitalize = false,
}) => {
  const handleButtonClick = () => {
    onSelect(button.id);
  };
  const token = getLocalStorageItem('TOKEN');
  const textToShow = capitalize
    ? capitalizeWords(button?.label)
    : button?.label;

  return (
    <ContainerButtonsZones>
      <Tooltip text={textToShow.length > characters ? textToShow : ''}>
        <div
          className='button-assigned size-zones'
          onClick={() => {
            token && token.role !== 'equipo_comercial' && handleButtonClick();
          }}
        >
          <p>
            {textToShow.slice(0, characters)}
            {textToShow.length > characters && '...'}
          </p>
          {token && token.role !== 'equipo_comercial' && (
            <button>
              <img src={icoCloseModal} alt='iconClose' />
            </button>
          )}
        </div>
      </Tooltip>
    </ContainerButtonsZones>
  );
};

export default ButtonZones;
