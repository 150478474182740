import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllMxSellers } from 'service/teams/teams.api';
import { useTeamManageForm } from './hook/useTeamManageForm';
import { Button, DropdownButton } from '@habitech/call-to-action';
import { HabiModal } from '@habitech/habi-modal';
import { Search, SimpleTextField } from '@habitech/keychain-atoms-inputs';
import { Loader } from '@habitech/loader';
import { ROLES } from 'shared/constants';
import {
  getLocalStorageItem,
  removeSpecialCharactersAndSpaces,
} from 'utils/funcionHelper';
import ButtonZones from '../buttonZones/ButtonZones';
import {
  ContainerSelectors,
  Title,
  ZoneOptions,
} from 'pages/maturesZones/components/SelectionMultipleOptions/SelectionMultipleOptions.styles';
import InputLockedCounterDays from '../inputLockedCounterDays/InputLockedCounterDays';

import { type IOption, type ITeamManageForm } from 'pages/teamManage/interface';
import {
  ErrorMessage,
  FormContainer,
  FormDivision,
  FormFooter,
  FormItem,
  FormItemAssignation,
  FormTitle,
  LoaderContainer,
  ResumenContainer,
  ResumenDivider,
  ResumenItem,
  Separator,
  TeamManageFormContainer,
} from './teamManageForm.styles';
import { LigthText } from 'components/formRegister/FormRegister.styles';

const TeamManageForm = ({
  selectedData,
  setSelectedData,
  saveForm,
  handleCancel,
  ready,
}: ITeamManageForm) => {
  const [leaders, setLeaders] = useState<IOption[]>([]);
  const { id } = useParams();
  const { role } = getLocalStorageItem('TOKEN');

  const {
    zones,
    errorZone,
    errorZoneAvailable,
    loaded,
    errorLabel,
    selectedZones,
    sellers,
    selectedSellers,
    validateModel,
    modalCounter,
    setSellers,
    setSelectedZones,
    setSelectedSellers,
    setSellersInformation,
    setValidateModel,
    convertToOptions,
    handleSelectSeller,
    handleSearchZone,
    handleSelectZone,
    handleChangeSeller,
    handleChangeLeader,
    handleChangeTeamLabel,
    handleSelectModel,
    deleteSeller,
    deleteZone,
    onCloseModalModelAssignation,
  } = useTeamManageForm({ selectedData, setSelectedData });

  useEffect(() => {
    // Get all leaders
    getAllMxSellers({ leader: true, pagination: 1, limit: 100 })
      .then((response) => {
        if (response.status === 200) {
          const users = response.data.users.filter(
            (user: any) => user.seller_state_label !== 'Inactivo'
          );
          const options = convertToOptions('name', 'id', users);
          setLeaders(options);
        }
      })
      .catch(() => {
        setLeaders([{ label: 'Sin opciones', value: '' }]);
      });

    // Get all sellers
    getAllMxSellers({ for_teams: true }).then((response) => {
      if (response.status === 200) {
        const filteredSellers = response.data.users.filter(
          (item: any) => item.available === 0
        );
        const options = convertToOptions('name', 'id', filteredSellers);
        setSellers(options);
        setSellersInformation(filteredSellers);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedZones(selectedData.median_zones);
    setSelectedSellers(selectedData.sellers);
  }, [selectedData]);

  useEffect(() => {
    if (
      selectedData.model_assignation !== 'round_robin' &&
      selectedData.model_assignation !== 'persefone' &&
      selectedData.model_assignation !== 'model_3' &&
      selectedData.model_assignation !== 'round_robin_category' &&
      selectedData.model_assignation !== 'cascade'
    ) {
      setValidateModel(false);
    } else {
      setValidateModel(true);
    }
  });

  const searchLeader = (id: number) => {
    const leader = leaders.find((item) => Number(item.value) === id);
    if (leader) {
      return leader.label;
    } else {
      return '';
    }
  };

  const options: IOption[] = [
    { label: 'Round Robin', value: 'round_robin' },
    { label: 'Persefone', value: 'persefone' },
    { label: 'Modelo 3', value: 'model_3' },
    {
      label: 'Round Robin por categoría',
      value: 'round_robin_category',
    },
    { label: 'Asignación en cascada', value: 'cascade'},
  ];

  const getLabelByValue = (value: string): string => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : '';
  };

  const MODAL_SUBTITLE = {
    subtitle: (
      <>
        <LigthText>
          Una vez pasen los 30 días, podrás cambiar el modelo de asignación del
          equipo.
        </LigthText>
      </>
    ),
  };
  return (
    <TeamManageFormContainer>
      <FormTitle>
        <div className='title'>
          {role === ROLES.TEAM_SELLER
            ? 'Información del equipo'
            : id
            ? `Editando equipo ${selectedData.name}`
            : 'Crear un nuevo equipo'}
        </div>
        {id === undefined && (
          <div className='subtitle'>
            Completa los datos que aparecen abajo y al final, haz clic en
            Guardar. <strong>¡Empecemos!</strong>
          </div>
        )}
      </FormTitle>
      <FormDivision />
      <FormContainer>
        {(sellers.length === 0 && leaders.length === 0) ||
        (id &&
          selectedData.median_zones.length === 0 &&
          selectedData.sellers.length === 0 &&
          id === undefined) ? (
          <LoaderContainer>
            <Loader size='small' />
          </LoaderContainer>
        ) : (
          <>
            <FormItem>
              {role !== ROLES.TEAM_SELLER && (
                <Search
                  dataId='zone-search'
                  label='Zonas medianas'
                  listOptions={zones}
                  onChange={(name, value) => {
                    handleSearchZone(value);
                  }}
                  onSelectItem={(item) => {
                    handleSelectZone(item);
                  }}
                  name='zone-search'
                  loading={loaded}
                  placeHolder='zm_14_zm114'
                  error={errorZone || errorZoneAvailable}
                />
              )}
              {errorZone && (
                <ErrorMessage>
                  <svg
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.99984 12.8333C10.2215 12.8333 12.8332 10.2216 12.8332 6.99996C12.8332 3.7783 10.2215 1.16663 6.99984 1.16663C3.77817 1.16663 1.1665 3.7783 1.1665 6.99996C1.1665 10.2216 3.77817 12.8333 6.99984 12.8333ZM6.99984 5.24996C7.32201 5.24996 7.58317 4.98879 7.58317 4.66663C7.58317 4.34446 7.32201 4.08329 6.99984 4.08329C6.67766 4.08329 6.4165 4.34446 6.4165 4.66663C6.4165 4.98879 6.67766 5.24996 6.99984 5.24996ZM6.4165 6.41663C6.4165 6.09445 6.67766 5.83329 6.99984 5.83329C7.32201 5.83329 7.58317 6.09445 7.58317 6.41663V9.33329C7.58317 9.65547 7.32201 9.91663 6.99984 9.91663C6.67766 9.91663 6.4165 9.65547 6.4165 9.33329V6.41663Z'
                      fill='#E61717'
                    ></path>
                  </svg>
                  <p>No se encontraron resultados</p>
                </ErrorMessage>
              )}
              {errorZoneAvailable && (
                <ErrorMessage>
                  <svg
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.99984 12.8333C10.2215 12.8333 12.8332 10.2216 12.8332 6.99996C12.8332 3.7783 10.2215 1.16663 6.99984 1.16663C3.77817 1.16663 1.1665 3.7783 1.1665 6.99996C1.1665 10.2216 3.77817 12.8333 6.99984 12.8333ZM6.99984 5.24996C7.32201 5.24996 7.58317 4.98879 7.58317 4.66663C7.58317 4.34446 7.32201 4.08329 6.99984 4.08329C6.67766 4.08329 6.4165 4.34446 6.4165 4.66663C6.4165 4.98879 6.67766 5.24996 6.99984 5.24996ZM6.4165 6.41663C6.4165 6.09445 6.67766 5.83329 6.99984 5.83329C7.32201 5.83329 7.58317 6.09445 7.58317 6.41663V9.33329C7.58317 9.65547 7.32201 9.91663 6.99984 9.91663C6.67766 9.91663 6.4165 9.65547 6.4165 9.33329V6.41663Z'
                      fill='#E61717'
                    ></path>
                  </svg>
                  <p>No hay zonas disponibles para esta búsqueda</p>
                </ErrorMessage>
              )}
              <Separator />
              {selectedZones.length > 0 && (
                <ContainerSelectors type='primary'>
                  <Title>
                    {role === ROLES.TEAM_SELLER
                      ? 'Zonas medianas asignadas'
                      : 'Zonas medianas seleccionadas'}
                  </Title>
                  <ZoneOptions>
                    {selectedZones.map((item: any, index: number) => {
                      return (
                        <ButtonZones
                          key={index}
                          button={item}
                          onSelect={() => {
                            deleteZone(item.id);
                          }}
                        />
                      );
                    })}
                  </ZoneOptions>
                </ContainerSelectors>
              )}
            </FormItem>
            <FormItem>
              {role !== ROLES.TEAM_SELLER && (
                <Search
                  dataId='seller-search'
                  label='Sellers disponibles'
                  listOptions={sellers}
                  onChange={(name, value) => {
                    handleChangeSeller(value);
                  }}
                  onSelectItem={(item) => {
                    handleSelectSeller(item);
                  }}
                  name='zone-search'
                  placeHolder='Seleccionar'
                />
              )}
              <Separator />
              {selectedSellers.length > 0 && (
                <ContainerSelectors type='secondary'>
                  <Title>
                    {role === ROLES.TEAM_SELLER
                      ? 'Sellers asignados'
                      : 'Sellers disponibles seleccionados'}
                  </Title>
                  <ZoneOptions>
                    {selectedSellers.map((item, index) => {
                      return (
                        <ButtonZones
                          key={index}
                          button={item}
                          onSelect={() => {
                            deleteSeller(item.value);
                          }}
                          capitalize={true}
                        />
                      );
                    })}
                  </ZoneOptions>
                </ContainerSelectors>
              )}
            </FormItem>
            {role !== ROLES.TEAM_SELLER ? (
              <>
                <FormItem>
                  <DropdownButton
                    dataId='leaders'
                    label='Líder comercial'
                    setValue={(value) => {
                      handleChangeLeader(value);
                    }}
                    listOptions={leaders}
                    variant='white'
                    placeholder='Seleccionar'
                    size='large fluid'
                    value={selectedData.leader_id}
                  />
                </FormItem>
                <FormItem>
                  <SimpleTextField
                    dataId='team-name'
                    name='team'
                    label='Nombre del equipo'
                    placeholder='Asignar nombre'
                    defaultValue=''
                    value={selectedData.name}
                    error={errorLabel}
                    errorMessage='Mínimo 5 caracteres'
                    onChange={(e: any) => {
                      handleChangeTeamLabel(e.target.value, 'name', id);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <SimpleTextField
                    name='label'
                    label='Label'
                    placeholder='Asignar nombre'
                    defaultValue=''
                    value={
                      id
                        ? selectedData.label
                        : removeSpecialCharactersAndSpaces(selectedData.name)
                    }
                    onChange={() => {}}
                    disabled={true}
                  />
                </FormItem>
                {selectedData?.last_update_model_days &&
                selectedData?.last_update_model_days > 0 ? (
                  <InputLockedCounterDays
                    label='Modelo de asignación'
                    modelName={getLabelByValue(selectedData.model_assignation)}
                    counter={selectedData?.last_update_model_days}
                  />
                ) : (
                  <FormItemAssignation>
                    <DropdownButton
                      dataId='model_assignation'
                      label='Modelo de asignación'
                      setValue={(value) => {
                        handleSelectModel(value);
                      }}
                      listOptions={[
                        { label: 'Round Robin', value: 'round_robin' },
                        { label: 'Persefone', value: 'persefone' },
                        { label: 'Modelo 3', value: 'model_3' },
                        {
                          label: 'Round Robin por categoría',
                          value: 'round_robin_category',
                        },
                        { label: 'Asignación en cascada', value: 'cascade' },
                      ]}
                      variant='white'
                      placeholder='Seleccionar'
                      size='large fluid'
                      value={selectedData.model_assignation || ''}
                      error={!validateModel && id !== undefined}
                      errorMessage='Debes seleccionar un modelo de asignación'
                    />
                  </FormItemAssignation>
                )}
              </>
            ) : (
              <ResumenContainer>
                <ResumenItem>
                  <div className='title'>Líder Comercial:</div>
                  <div className='description'>
                    {searchLeader(selectedData.leader_id)}
                  </div>
                </ResumenItem>
                <ResumenDivider />
                <ResumenItem>
                  <div className='title'>Nombre del equipo:</div>
                  <div className='description'>{selectedData.name}</div>
                </ResumenItem>
                <ResumenDivider />
                <ResumenItem>
                  <div className='title'>Label:</div>
                  <div className='description'>{selectedData.label}</div>
                </ResumenItem>
                <ResumenDivider />
                <ResumenItem>
                  <div className='title'>Modelo de asignación:</div>
                  <div className='description'>
                    {selectedData.model_assignation !== null
                      ? selectedData.model_assignation.charAt(0).toUpperCase() +
                        selectedData.model_assignation
                          .slice(1)
                          .replace('_', ' ')
                      : ''}
                  </div>
                </ResumenItem>
              </ResumenContainer>
            )}
          </>
        )}
      </FormContainer>
      {role !== ROLES.TEAM_SELLER && (
        <FormFooter>
          <Button
            dataId='cancel'
            variant='ghost'
            size='large'
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            dataId='save'
            variant='primary'
            size='large'
            onClick={() => {
              saveForm(id);
            }}
            disabled={!ready}
          >
            Guardar
          </Button>
        </FormFooter>
      )}

      <HabiModal
        dataId='selection_model'
        open={modalCounter}
        title='El modelo de asignación solo puede editarse cada 30 días'
        subtitle={MODAL_SUBTITLE.subtitle as any}
        primaryBtn='Aceptar'
        onClose={onCloseModalModelAssignation}
        onClickPrimary={onCloseModalModelAssignation}
      />
    </TeamManageFormContainer>
  );
};

export default TeamManageForm;
