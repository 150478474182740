import * as jose from 'jose';
import { type TCredentialData, type TGoogleResponse } from './model';

import GoogleButton from '../googleButton/GoogleButton';
import { authUser } from 'service/auth/auth.api';

export interface IUserGoogle {
  // roles: string[];
  email: string;
  name: string;
  lastName: string;
  imageProfile: string;
  exp: number;
  iat: number;
}
export interface Itoken {
  token: string;
  country: string;
  refreshToken: string;
  role: string;
  message: string;
  success: boolean;
  userName: string;
}
interface LoginGoogleProps {
  onNextStep: (user: IUserGoogle) => void;
  onError: (error: unknown) => void;
  setIsLogin?: (ban: boolean) => void;
  setError?: (error: string) => void;
}

const LoginGoogle = ({
  onNextStep,
  onError,
  setIsLogin,
  setError,
}: LoginGoogleProps) => {
  const onSuccessLogin = async (googleResponse: TGoogleResponse) => {
    if (setIsLogin) {
      setIsLogin(true);
    }
    if (!('credential' in googleResponse)) {
      onError('user offline');
      return;
    }
    const data: TCredentialData = jose.decodeJwt(googleResponse.credential);
    localStorage.setItem('exp', JSON.stringify(data.exp));

    // Check country
    const country = String(localStorage.getItem('country'));
    try {
      const { data: dataToken } = await authUser(
        googleResponse.credential,
        country
      );
      if (dataToken) {
        localStorage.setItem('TOKEN', JSON.stringify(dataToken));
      }
      if (data) {
        onNextStep({
          email: data.email as string,
          name: data.given_name as string,
          lastName: data.family_name as string,
          imageProfile: data.picture as string,
          exp: data.exp as number,
          iat: data.iat as number,
        });
      } else {
        onError('user no permission');
      }
    } catch (error) {
      if (setIsLogin) {
        setIsLogin(false);
      }
      if (setError) {
        setError('Ocurrió un error o no estás asignado al pool de usuarios');
      }
    }
  };

  return (
    <div>
      <GoogleButton onGoogleSignIn={onSuccessLogin} />
    </div>
  );
};

export default LoginGoogle;
