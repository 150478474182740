import React, { lazy } from 'react';
import { type IRouter } from './Routes';
import SelectCountry from 'pages/selectCountry/SelectCountry';
import MaturesZones from 'pages/maturesZones/MaturesZones';
import Login from 'components/login/Login';
import SelectActions from '../pages/selectActions/SelectActions';
import TeamList from 'pages/teamList/TeamList';
import CardForm from 'pages/maturesZones/components/cardForm/CardForm';
import CardFormLeader from 'pages/maturesZones/components/cardForm/CardFormLeader';
import TeamManage from 'pages/teamManage/TeamManage';

const FormRegister = lazy(
  async () => await import('components/formRegister/FormRegister')
);
const Page404 = lazy(async () => await import('../pages/404/Page404'));
const Home = lazy(async () => await import('../pages/home/Home'));
// const Login = lazy(async () => await import('../components/login/Login'));

const router: IRouter[] = [
  {
    path: '/',
    element: <SelectCountry />,
    key: 0,
  },
  {
    path: '/login',
    element: <Login />,
    key: 1,
  },
  {
    path: '/actions',
    element: <SelectActions />,
    privateR: true,
    key: 2,
  },
  {
    path: '/home',
    element: <Home />,
    privateR: true,
    key: 3,
  },
  {
    path: '/equipos/:save?',
    element: <TeamList />,
    privateR: true,
    key: 4,
  },
  {
    path: '/form/:name/:id',
    element: <FormRegister />,
    privateR: true,
    key: 5,
  },
  {
    path: '/form/:name',
    element: <FormRegister />,
    privateR: true,
    key: 6,
  },
  {
    path: '/zonas-maduras',
    element: <MaturesZones />,
    privateR: true,
    key: 7,
  },
  {
    path: '/zonas-maduras/create',
    element: <CardForm />,
    privateR: true,
    key: 8,
  },
  {
    path: '/zonas-maduras/edit/:id',
    element: <CardForm />,
    privateR: true,
    key: 9,
  },
  {
    path: '/zonas-medianas',
    element: <CardFormLeader />,
    privateR: true,
    key: 10,
  },
  {
    path: '/bo-page',
    element: <Page404 />,
    privateR: true,
    key: 11,
  },
  {
    path: '*',
    element: <Page404 />,
    key: 12,
  },
  {
    path: '/equipos/crear',
    element: <TeamManage />,
    privateR: true,
    key: 13,
  },
  {
    path: '/equipos/editar/:id',
    element: <TeamManage />,
    privateR: true,
    key: 14,
  },
  {
    path: '/404',
    element: <Page404 />,
    privateR: false,
    key: 15,
  },
];

export default Object.freeze(router);
