export const TEXT_FORM_MATURES_ZONES = {
  titleCreate: 'Crear nueva zona madura',
  titleEdit: 'Editar zona madura',
  subtitleCreate:
    'Para crear una nueva zona madura, primero elige una ciudad, luego selecciona las zonas medianas disponibles y para poder guardar completa todos los datos.',
};

export const TEXT_LABEL_ASSIGN_SELLER = {
  empty: 'Selecciona la zona mediana para ver los sellers asignados',
  selected: 'Estos son los sellers asignados a la zona mediana que seleccionaste',
  unselected: 'Aún no has seleccionado sellers'
  
}

export const TEXT_LABEL_AVAILABLE_SELLER = {
  empty: 'Selecciona la zona mediana para ver los sellers disponibles',
  unselected: 'Estos son los sellers disponibles para asignación',
  selected: 'En este momento no hay sellers disponibles'
}

export const DataFake1 = [
  {
    id: 0,
    label: 'zona  1',
    selected: false,
  },
  {
    id: 1,
    label: 'zona  2',
    selected: false,
  },
  {
    id: 2,
    label: 'zona  3',
    selected: false,
  },
  {
    id: 3,
    label: 'zona  4',
    selected: false,
  },
  {
    id: 4,
    label: 'zona  5',
    selected: false,
  },
  {
    id: 5,
    label: 'zona  6',
    selected: false,
  },
  {
    id: 6,
    label: 'zona  7',
    selected: false,
  },
  {
    id: 7,
    label: 'zona  8',
    selected: false,
  },
  {
    id: 8,
    label: 'zona  9',
    selected: false,
  },
  {
    id: 9,
    label: 'zona  10',
    selected: false,
  },
  {
    id: 10,
    label: 'zona 11',
    selected: false,
  },
  {
    id: 11,
    label: 'zona 12',
    selected: false,
  },
  {
    id: 12,
    label: 'zona 13',
    selected: false,
  },
  {
    id: 13,
    label: 'zona 14',
    selected: false,
  },
  {
    id: 14,
    label: 'zona 15',
    selected: false,
  },
  {
    id: 15,
    label: 'zona 16',
    selected: false,
  },
  {
    id: 16,
    label: 'zona 17',
    selected: false,
  },
  {
    id: 17,
    label: 'zona 18',
    selected: false,
  },
  {
    id: 18,
    label: 'zona 19',
    selected: false,
  },
  {
    id: 19,
    label: 'zona 20',
    selected: false,
  },
  {
    id: 20,
    label: 'zona 21',
    selected: false,
  },
  {
    id: 21,
    label: 'zona 22',
    selected: false,
  },
  {
    id: 22,
    label: 'zona 23',
    selected: false,
  },
  {
    id: 23,
    label: 'zona 24',
    selected: false,
  },
  {
    id: 24,
    label: 'zona 25',
    selected: false,
  },
  {
    id: 25,
    label: 'zona 26',
    selected: false,
  },
  {
    id: 26,
    label: 'zona 27',
    selected: false,
  },
  {
    id: 27,
    label: 'zona 28',
    selected: false,
  },
  {
    id: 28,
    label: 'zona 29',
    selected: false,
  },
  {
    id: 29,
    label: 'zona 30',
    selected: false,
  },
];

export const DataFake2 = [
  {
    id: 0,
    label: 'Inés Tornudo',
    selected: false,
  },
  {
    id: 1,
    label: 'Nori Navas',
    selected: false,
  },
  {
    id: 2,
    label: 'Elton Tito',
    selected: false,
  },
  {
    id: 3,
    label: 'Helen Chufe',
    selected: false,
  },
  {
    id: 4,
    label: 'Lola Mento',
    selected: false,
  },
  {
    id: 5,
    label: 'Alan Brito',
    selected: false,
  },
  {
    id: 6,
    label: 'Ali Cate',
    selected: false,
  },
  {
    id: 7,
    label: 'Armando Bronca',
    selected: false,
  },
  {
    id: 8,
    label: 'Alba Sura',
    selected: false,
  },
  {
    id: 9,
    label: 'Josefa Nática',
    selected: false,
  },
];
export const DataFake3 = [
  {
    id: 10,
    label: 'Bruce Wayne',
    selected: false,
  },
  {
    id: 11,
    label: 'Tony Start',
    selected: false,
  },
  {
    id: 12,
    label: 'Mike Morales',
    selected: false,
  },
  {
    id: 13,
    label: 'Peter Parker',
    selected: false,
  },
  {
    id: 14,
    label: 'Clark Kent',
    selected: false,
  },
  {
    id: 15,
    label: 'Bruce Banner',
    selected: false,
  },
  {
    id: 16,
    label: 'Barry Alen',
    selected: false,
  },
  {
    id: 17,
    label: 'Wanda Maximoff',
    selected: false,
  },
  {
    id: 18,
    label: 'Diana Prince',
    selected: false,
  },
  {
    id: 19,
    label: 'Natasha Romanoff',
    selected: false,
  },
];
