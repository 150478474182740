import { useEffect, useState } from 'react';

import { Button } from '@habitech/call-to-action';

import { WrapperTable } from 'components/tableP/table/TablePrincipal.style';

import Pagination from 'components/tableP/pagination/Pagination';
import { Loader } from '@habitech/loader';

import { ContainerTable } from '../teamList/TeamList.styles';
import { ROUTER_URL } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import { imgGoBackPurple, editIcon, imgPeopleSearch } from 'image';
import { getZoneMatures } from 'service/mature/mature.api';
import { Header } from './components/cardForm/CardForm.styles';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'store/slice/userSlice';
import { Helmet } from 'react-helmet';
import GenericTable from 'components/GenericTable/GenericTable';
import { StyledLabelStatus } from './components/cardForm/ItemTableMature.styles';

import { type IDataForTable, type IDataMatureZone } from './models/models';

export const labelsTable = [
  { id: 1, label: 'Nombre en BBDD', name: 'name', sort: false },
  { id: 2, label: 'Label', name: 'label', sort: false },
  { id: 3, label: 'Ciudad', name: 'city_label', sort: false },
  { id: 4, label: 'Estado', name: 'active', sort: false },
  { id: 5, label: 'Acciones', name: 'actions', sort: false },
];

const MaturesZones = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(10);
  const [pagination, setPagination] = useState<number>(1);
  const [totalData, setTotalData] = useState<number>(1);
  const [matureZone, setMatureZone] = useState<IDataMatureZone[]>([]);
  const [dataForTable, setDataForTable] = useState<IDataForTable[]>([]);
  
  const routerCreate = () => {
    navigator(ROUTER_URL.MATURES_ZONE_CREATE);
  };

  const handleGetListMatureZone = async (pages: number) => {
    setIsLocalLoading(true);
    try {
      const { data } = await getZoneMatures({
        country: 'CO',
        pagination: currentLimit,
        offset: pages,
      });
      setMatureZone(data?.data.data);
      setTotalData(data?.data.total);
      setPagination(Math.ceil(data?.data.total / currentLimit));
      setIsLocalLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsLoading(true));
    }
  };

  const onPageChange = (pages: number) => {
    const newData = (pages - 1) * currentLimit;
    handleGetListMatureZone(newData);
  };
  const routerMaturesHome = () => {
    navigator(ROUTER_URL.ACTIONS);
  };

  const editZone = (id:number) => {
    navigator(`${ROUTER_URL.MATURES_ZONE_EDIT}/${id}`);
  };

  useEffect(() => {
    handleGetListMatureZone(0);
  }, [])
  
  useEffect(() => {
    const arrayMatureZones:any  = [];
    matureZone?.forEach((zone:any) => {
      arrayMatureZones.push({
        ...zone,
        active: (
          <StyledLabelStatus status={zone.active}>
            {zone.active === 1 ? 'Activo' : 'Inactivo'}
          </StyledLabelStatus>
        ),
        actions: (
          <div className='actions'>
            <Button
              dataId='text-id'
              variant='ghost'
              onClick={() => {
                editZone(zone.id);
              }}
            >
              <img src={editIcon} style={{ width: '24px', height: '24px' }} />
            </Button>
          </div>
        ),
      })
    })
    setDataForTable(arrayMatureZones);
  }, [matureZone])

  useEffect(() => {
    onPageChange(1);
  }, [currentLimit]);

  return (
    <div>
      <Helmet>
        <title>Zonas Maduras</title>
      </Helmet>
      <Header>
        <Button
          dataId='btn-redirect-home'
          iconLeft={
            <img src={imgGoBackPurple} alt='regresar a home' width={36} />
          }
          onClick={routerMaturesHome}
          variant='ghost'
        >
          Regresar
        </Button>
      </Header>
      <ContainerTable>
        <WrapperTable>
          <header>
            <h1>Listado de zonas Maduras</h1>
            <Button
              dataId='test-id'
              onClick={routerCreate}
              size='large'
            >
              Crear zona Madura
            </Button>
          </header>

          <div className='info-table'>
            { isLocalLoading ? (
                <div className='loading-table'>
                  <Loader />
                </div>
              ) : (
                <>
                  {
                    dataForTable?.length === 0 ? (
                      <div className='no-result-img'>
                        <p className='title-no-result'>Sin resultados</p>
                        <img
                          className='imagen-no-result'
                          src={imgPeopleSearch}
                          alt='No hay registros'
                        />
                      </div>
                    ) : (
                      <GenericTable defaultColumns={labelsTable} data={dataForTable} />
                    )
                  }
                </>
              )
            }
            { dataForTable.length > 0 && !isLocalLoading && (
              <Pagination
                limitOptions={[10, 20]}
                totalPages={pagination}
                currentLimit={currentLimit}
                setCurrentLimit={setCurrentLimit}
                onPageChange={onPageChange}
                onLimitChange={() => {}}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalLength={totalData}
              />
            )}
          </div>
        </WrapperTable>
      </ContainerTable>
    </div>
  );
};

export default MaturesZones;
