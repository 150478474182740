import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import mapsReducer from './slice/mapsSlice';
import usersReducer from './slice/userSlice';
import teamsReducer from './slice/teamSlice';

const store = configureStore({
  reducer: {
    maps: mapsReducer,
    user: usersReducer,
    teams: teamsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
