export const capitalizeWords = (sentence: string) => {
  if (!sentence) return '';

  const lowercasedSentence = sentence.toLowerCase();

  return lowercasedSentence
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
export const wordCaracterPhone = (phone: string) => {
  if (!phone) {
    return '-';
  }
  if (phone.length < 3) {
    return phone;
  }
  const wordInit = phone?.substring(0, 3);
  const wordComplete = phone?.substring(3);
  return `${wordInit} ${wordComplete}`;
};

export const removeSpecialCharactersAndSpaces = (
  inputString: string
): string => {
  let cleanedString = inputString.replace(/[^\w\s]+/g, '_');
  cleanedString = cleanedString.replace(/\s+/g, '_');

  while (cleanedString.endsWith('_')) {
    cleanedString = cleanedString.slice(0, -1);
  }

  return cleanedString.toLowerCase();
};

export const areObjectsEqual = (objA: any, objB: any): boolean => {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) {
    return false;
  }
  for (const key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }
  return true;
};
export const replaceUnderscoreAndCapitalize = (string: string): string => {
  const initChart = string.replace(/_/g, ' ').toLocaleLowerCase();
  return initChart.charAt(0).toUpperCase() + initChart.slice(1);
};

export const capitalizeStringNames = (name: string): string => {
  const splitName = name.split(' ').slice(0, 3);
  const convertedName = splitName.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return convertedName.join(' ');
};

export const getLocalStorageItem = (name: string) => {
  const storage = localStorage.getItem(name);
  if (storage) {
    return JSON.parse(storage);
  } else {
    return null;
  }
};
