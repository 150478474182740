import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import LocalRoutes from './RouterLocal';
import Layout from 'components/layout/Layout';

import Login, { type IUserAuth } from 'components/login/Login';
import { Loader } from '@habitech/loader';
import { ROUTER_URL } from 'shared/constants';

export interface IRouter {
  element: React.ReactNode;
  path: string;
  key: number;
  privateR?: boolean;
  errorElement?: React.ReactNode;
}

const RouterComponent = () => {
  const navigator = useNavigate();
  const routerPath: IRouter[] = [...LocalRoutes];
  const user: IUserAuth = JSON.parse(localStorage.getItem('user') as string);

  useEffect(() => {
    const actualDate = Math.round(Date.now() / 1000);
    const sessionTimeout = setTimeout(() => {
      localStorage.clear();
      navigator(ROUTER_URL.LOGIN);
    }, Math.round(Number(localStorage.getItem('exp')) - actualDate) * 1000 || 6000);

    return () => {
      clearTimeout(sessionTimeout);
    };
  }, []);
  return (
    <Suspense
      fallback={
        <div className='loading-table'>
          <Loader />{' '}
        </div>
      }
    >
      <Routes>
        {routerPath.map(({ path, element, key, privateR }: IRouter) => {
          if (!privateR)
            return <Route key={key} path={path} element={element} />;
          if (privateR && user?.isAuth) {
            return (
              <Route
                key={key}
                path={path}
                element={<Layout>{element}</Layout>}
              />
            );
          } else return <Route key={1} path={'/'} element={<Login />} />;
        })}
      </Routes>
    </Suspense>
  );
};

export default RouterComponent;
