import { useAppDispatch } from "store/store"
import { setIsLoading } from "store/slice/userSlice";
import { getTeamsList } from "service/teams/teams.api";
import { setTeams } from "store/slice/teamSlice";

const useTeams = () => {
  
  const dispatch = useAppDispatch();

  const setTeamsList = async () => {
    
    dispatch(setIsLoading(false));

    try {

      const data = await getTeamsList();
      dispatch(setTeams( data?.data ));
    
    } catch (error) {
      console.log(error);
    }

    dispatch(setIsLoading(true));
  }

  return {
    setTeamsList
  }
}

export default useTeams;