import styled from 'styled-components';

import {
  monserratRegular,
  scarpaGray500,
  electricPurple50,
  electricPurple600,
  electricPurple800,
  white,
} from '@habitech/shared';

export const LineDivider = styled.div`
  display: grid;
  grid-column: 1 / span 12;
  margin-bottom: 32px;
  background: #e6e5e7;
  height: 0.5px;
`;
export const GreetingContainer = styled.div`
  display: grid;
  grid-column: 1 / span 5;
  margin: 64px 0 32px 0;
`;
export const ContainerSubtitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledWrapper = styled.div.attrs({
  className: 'StyledWrapper',
})`
  text-align: center;
  width: 100%;
`;

export const StyledTitle = styled.div`
  color: ${electricPurple600};
  font-family: ${monserratRegular};
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
`;

export const StyledSubtitle = styled.div`
  color: ${scarpaGray500};
  font-family: ${monserratRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledContainerButtons = styled.div`
  display: flex;
  margin-top: 40px;
  place-content: center;
`;
interface IStyledButtonActions {
  country: string;
  color: string;
}

export const StyledButtonActions = styled.div<IStyledButtonActions>`
  grid-column: ${(props) =>
    props.country === 'MX' ? 'auto / span 6' : 'auto / span 4'};
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  background: ${(props) => props.color};
  padding: 32px;
  border: none;
  text-align: left;
  cursor: pointer;
  height: 392px;
  justify-content: space-between;
  margin-bottom: 16px;
  &:hover {
    border: 3px solid ${electricPurple800};
  }
`;

export const StyledDivider = styled.div.attrs({
  className: 'StyledDivider',
})`
  width: 225px;
  height: 1px;
  background: ${electricPurple50};
  margin: 24px 0;
`;

export const StyledTitleteam = styled.div`
  color: ${white};
  font-family: ${monserratRegular};
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
`;

export const StyledSubtitleActions = styled.p.attrs({
  className: 'StyledSubtitleActions',
})`
  color: ${electricPurple50};
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;
