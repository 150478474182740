import styled from 'styled-components';
import {
  monserratRegular,
  spanishViridian50,
  scarpaGray500,
  scarpaGray50,
  scarpaGray200,
  scarpaGray400,
  scarpaGray800,
  robotoRegular,
  white,
  skyBlue50,
  skyBlue600,
  turquoise50,
  turquoise600,
  size
} from '@habitech/shared';

export const mapStyles = {
  mapTypeControl: false,
  streetViewControl: false,
  styles: [
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#e5e9fb',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: scarpaGray50 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: scarpaGray200,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: scarpaGray200,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: scarpaGray200,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#696969',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: spanishViridian50,
        },
      ],
    },
  ],
};
export const ContainerFormZones = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
`;

export const FooterButton = styled.div`
  display: flex;
  justify-content: end;
`;

export const FooterMedianZone = styled.footer.attrs({
  className: 'FooterMedianZone',
})`
  display: flex;
  justify-content: end;
  height: 70px;
  align-items: center;
  border-top: 1px solid ${scarpaGray400};
`;

export const ContainerFormZonesLeader = styled.div.attrs({
  className: 'ContainerFormZonesLeader',
})`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;
`;
export const ContainerMedianZone = styled.div`
  width: 100%;
`;
export const CenteredDiv = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/7;
  @media screen and (max-width: ${size.tablet.max}) {
    grid-column: 1 / 5;
  }
`;
export const FormCard = styled.div`
  margin-top: 1rem;
  padding: 1rem 0;
  background: ${white};
  border-radius: 12px;
  grid-column: 1/7;
  @media screen and (max-width: ${size.tablet.max}) {
    grid-column: 1 / 5;
  }
`;
export const MapCard = styled.div`
  margin-top: 1rem;
  grid-column: 7/13;
  @media screen and (max-width: ${size.tablet.max}) {
    grid-column: 5 / 9;
  }
`;
export const Header = styled.div`
  margin-top: 1rem;
`;

export const WrapperContentMedianZone = styled.div.attrs({
  className: 'WrapperContentMedianZone',
})`
  height: calc(100vh - 345px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 0 0 0.35rem;
`

export const TitleCard = styled.div`
  font-family: ${monserratRegular};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
  color: ${scarpaGray800};
  margin-bottom: 16px;
`;

export const SubtitleCard = styled.div`
  color: ${scarpaGray500};
  font-family: ${robotoRegular};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const Line = styled.div.attrs({
  className: 'Line',
})`
  width: 100%;
  height: 1px;
  background-color: ${scarpaGray400};
  margin-bottom: 16px;
`;
export const ReelJump = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.h3.attrs({
  className: 'Label',
})`
  font-family: ${robotoRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
`;

export const SubtitleLabelCard = styled(SubtitleCard)`
  font-size: 12px;
`;

export const WrapperContentButtons = styled.div.attrs({
  className: 'WrapperContentButtons',
})<{type: string}>`
  border-radius: 8px;
  background: var(--habi-paleta-extendida-sky-blue-sky-blue-50, #EBF9FF);
  padding: 16px 12px;
  ${(props) => {
    switch (props.type) {
      case 'assigned':
        return `
          background-color: ${skyBlue50};
          .Label {
            color: ${skyBlue600};
          }
        `
      case 'available':
        return `
          background-color: ${turquoise50};
          .Label {
            color: ${turquoise600};
          }
        `
      default:
        return `background-color: ${white}`
    }
  }}
`
export const LabelName = styled.div`
  b {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
  }

  font-family: ${robotoRegular};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${scarpaGray500};
`;
