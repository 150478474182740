import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { setIsLoading, selectIsLoading } from 'store/slice/userSlice';
// import { setOpenModalRules } from 'store/slice/rulesSlice';
import { ROLES } from 'shared/constants';
import { type ISaveForm } from './interface';

import { Button } from '@habitech/call-to-action';
import { Loader } from '@habitech/loader';
import { HabiModal } from '@habitech/habi-modal';
import { getTeamById, saveTeam, updateTeam } from 'service/teams/teams.api';

import Maps from 'custom/mapsPolygos/Maps';
import TeamManageForm from './components/teamManageForm/TeamManageForm';
import { getLocalStorageItem } from 'utils/funcionHelper';
import { mapStyles } from './mapStylesJson/mapStyles';
import { imgGoBackPurple } from 'image';
import {
  ManageFormContainer,
  ManageHeader,
  ManageMapContainer,
  TeamManageContainer,
  MapCard,
} from './teamManage.styles';
import { LigthText } from 'components/formRegister/FormRegister.styles';

interface IModalProps {
  title: string;
  subtitle: any;
  description?: string;
  type: string;
  primary: string;
  secondary: string;
  disableSecondary: boolean;
}

const TeamManage = () => {
  const { role } = getLocalStorageItem('TOKEN');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector(selectIsLoading);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [ready, setReady] = useState(false);
  const [actualModel, setActualModel] = useState('');
  const [modalInformation, setModalInformation] = useState<IModalProps>({
    title: '',
    subtitle: '',
    description: '',
    type: '',
    primary: '',
    secondary: '',
    disableSecondary: false,
  });
  const [selectedData, setSelectedData] = useState<ISaveForm>({
    country: String(localStorage.getItem('country')),
    median_zones: [],
    sellers: [],
    leader_id: 0,
    name: '',
    label: '',
    model_assignation: '',
    update_model: false,
  });

  const [coordinates, setCoordinates] = useState<any>([]);
  const MODAL_SUBTITLE = {
    subtitle: (
      <>
        <LigthText>
          La información que has actualizado se perderá si regresas sin
          guardarla.
        </LigthText>
      </>
    ),
  };

  const handleCancel = () => {
    if (role === ROLES.TEAM_SELLER) {
      navigate('/equipos');
    } else {
      setShowModal(true);
      setModalInformation({
        title: '¿Quieres regresar sin guardar los cambios?',
        subtitle: MODAL_SUBTITLE.subtitle,
        type: 'cancel',
        primary: 'Sí, regresar',
        secondary: 'Cancelar',
        disableSecondary: false,
      });
    }
  };

  const saveForm = (id?: string) => {
    const temp = selectedData;
    const zoneIds: number[] = [];
    const sellerIds: number[] = [];
    setReady(false);

    temp.median_zones.map((zone) => {
      zoneIds.push(Number(zone.id));
      return false;
    });

    temp.sellers.map((seller) => {
      sellerIds.push(Number(seller.value));
      return false;
    });

    const dataToSave: ISaveForm = {
      country: selectedData.country,
      median_zones: zoneIds,
      sellers: sellerIds,
      leader_id: selectedData.leader_id,
      name: selectedData.name,
      label: selectedData.label,
      model_assignation: selectedData.model_assignation,
      update_model: !(actualModel === selectedData.model_assignation),
    };

    if (id === undefined) {
      saveTeam(dataToSave)
        .then((response) => {
          if (response.status === 201) {
            navigate('/equipos/save');
          }
        })
        .catch((error) => {
          setShowModal(true);
          setReady(true);
          if (error.response) {
            setModalInformation({
              title: 'Ocurrió un error',
              subtitle: error.response.data.message,
              description: '',
              type: 'error',
              primary: 'Entendido',
              secondary: '',
              disableSecondary: false,
            });
          } else {
            setModalInformation({
              title: 'Ocurrió un error',
              subtitle:
                'No se pudieron guardar los datos, El servicio fallo. Intenta nuevamente más tarde',
              description: '',
              type: 'error',
              primary: 'Entendido',
              secondary: '',
              disableSecondary: true,
            });
          }
        });
    } else {
      updateTeam(id, dataToSave)
        .then((response) => {
          if (response.status === 200) {
            navigate('/equipos/update');
          }
        })
        .catch((error) => {
          setShowModal(true);
          setReady(true);
          if (error.response) {
            setModalInformation({
              title: 'Ocurrió un error',
              subtitle: error.response.data.message,
              description: '',
              type: 'error',
              primary: 'Entendido',
              secondary: '',
              disableSecondary: true,
            });
          } else {
            setModalInformation({
              title: 'Ocurrió un error',
              subtitle: 'No se pudieron guardar los datos. Intenta nuevamente',
              description: '',
              type: 'error',
              primary: 'Entendido',
              secondary: '',
              disableSecondary: true,
            });
          }
        });
    }
  };

  const convertToOptions = (label: string, value: string, array: any) => {
    const options = array.map((item: any) => {
      return { label: item[label], value: item[value] };
    });

    return options;
  };

  useEffect(() => {
    if (
      selectedData.median_zones.length > 0 &&
      selectedData.sellers.length > 0 &&
      selectedData.leader_id !== 0 &&
      selectedData.leader_id !== null &&
      selectedData.name.length >= 5 &&
      ((selectedData.name !== '' && selectedData.name !== null) ||
        id !== undefined) &&
      selectedData.model_assignation !== null &&
      selectedData.model_assignation !== '' &&
      selectedData.model_assignation !== 'model1' &&
      selectedData.model_assignation !== 'modelo_2' &&
      selectedData.model_assignation !== 'modelo2prueba'
    ) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [selectedData]);

  useEffect(() => {
    setCoordinates(selectedData?.median_zones);
  }, [selectedData?.median_zones]);

  useEffect(() => {
    if (id) {
      handleGetTeamById();
    } else {
      dispatch(setIsLoading(true));
    }
  }, []);

  const handleGetTeamById = async () => {
    if (!id) {
      return;
    }
    try {
      dispatch(setIsLoading(false));
      let pagination = 0;
      let calls;
      const newZonas: any[] = [];
      const temp = { ...selectedData };
      do {
        const response = await getTeamById(id, { pagination });
        if (!calls) {
          calls = response.data.total_pages;
        }
        if (calls >= 0) {
          temp.sellers = convertToOptions('name', 'id', response.data.sellers);
          temp.leader_id = response.data.leader_id;
          temp.name = response.data.name;
          temp.label = response.data.label;
          temp.model_assignation = response.data.model_assignation;
          temp.last_update_model_days = response.data.last_update_model_days;
          pagination += 250;
        }
        calls -= 1;
        newZonas.push(...response.data.zones);
        temp.median_zones = newZonas.flat();
      } while (calls >= 1);
      setActualModel(temp.model_assignation);
      setSelectedData(temp);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsLoading(true));
    }
  };

  const setCoordinatesMap = coordinates?.length ? coordinates : [];

  // const openModalRules = (): void => {
  //   dispatch(setOpenModalRules(true));
  // };
  return (
    <TeamManageContainer>
      <ManageHeader>
        <Button
          dataId='redirect_teams'
          variant='ghost'
          iconLeft={
            <img src={imgGoBackPurple} alt='regresar a home' width={36} />
          }
          onClick={handleCancel}
        >
          Regresar
        </Button>
        {/* {id &&
          <Button
            dataId='open_modal_rules'
            variant='primary'
            onClick={openModalRules}
            size='large'
          >
            Reglas de equipo
          </Button>
        } */}
      </ManageHeader>
      <div className='grid-habi'>
        <ManageFormContainer>
          <TeamManageForm
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            saveForm={saveForm}
            handleCancel={handleCancel}
            ready={ready}
          />
        </ManageFormContainer>
        <ManageMapContainer>
          <MapCard>
            {isLoading ? (
              <Maps
                data={setCoordinatesMap}
                width='100%'
                mapStyles={mapStyles}
              />
            ) : (
              <Loader size='small' />
            )}
          </MapCard>
        </ManageMapContainer>
      </div>
      <HabiModal
        dataId='team-modal'
        open={showModal}
        title={modalInformation.title}
        subtitle={modalInformation.subtitle}
        description={modalInformation.description}
        secondaryBtn={modalInformation.secondary}
        primaryBtn={modalInformation.primary}
        disabledSecondaryButton={modalInformation.disableSecondary}
        onClose={() => {
          setShowModal(false);
        }}
        onClickSecondary={() => {
          setShowModal(false);
        }}
        onClickPrimary={() => {
          if (modalInformation.type === 'cancel') {
            navigate('/equipos');
          }
          if (modalInformation.type === 'error') {
            setShowModal(false);
          }
        }}
      />
    </TeamManageContainer>
  );
};

export default TeamManage;
