import {
  monserratRegular,
  robotoRegular,
  scarpaGray200,
  scarpaGray50,
  scarpaGray500,
  turquoise50,
  turquoise600,
  tomatoCoral100,
  tomatoCoral500,
  skyBlue100,
  blueLinks,
} from '@habitech/shared';
import { arrowUp } from 'image';
import styled from 'styled-components';
import type { IGenericTheadProps, IStateSeller } from './model';

const TableStyled = styled.table`
  width: 100%;
  border: 0.5px solid #dddddd;
  border-radius: 8px;
  border-spacing: 0;
`;
const TheadStyled = styled.thead`
  font-family: ${monserratRegular};
  border-bottom: 0.5px solid ${scarpaGray200};
  text-align: left;
`;
const TbodyStyled = styled.tbody`
  text-align: left;
`;
const TrStyled = styled.tr`
  height: 56px;
`;

const ThStyled = styled.th<IGenericTheadProps>`
  border-bottom: 0.5px solid ${scarpaGray200};
  border-right: 0.5px solid ${scarpaGray200};
  background: ${scarpaGray50};
  padding: 16px;
  position: relative;

  &:first-child {
    border-radius: 8px 0 0 0;
  }

  &:last-child {
    border-right: 0;
    border-radius: 0 8px 0 0;
  }

  &:before {
    right: 1em;
    content: ${({ sort }) => sort && `url(${arrowUp})`};
    position: absolute;
    top: 13px;
    filter: ${(props) =>
      props.order === 'asc' ? 'brightness(10%)' : 'brightness(95%)'};
  }

  &:after {
    content: ${({ sort }) => sort && `url(${arrowUp})`};
    opacity: 1;
    right: 1em;
    position: absolute;
    transform: rotate(180deg);
    top: 26px;
    filter: ${({ order }) =>
      order === 'desc' ? 'brightness(10%)' : 'brightness(95%)'};
  }
  &:hover {
    cursor: ${(props) => (props.sort ? 'pointer' : '')};
  }
`;

const TrBodyStyled = styled.tr`
  height: 40px;
  &:last-child {
    td {
      border-bottom: 0;
    }
  }
  &:hover {
    background-color: ${scarpaGray50};
  }
`;

const TdStyled = styled.td`
  padding: 0 16px;
  font-family: ${robotoRegular};
  color: ${scarpaGray500};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;

  border-right: 0.5px solid ${scarpaGray200};
  border-bottom: 0.5px solid ${scarpaGray200};
  .text-image {
    display: flex;
    align-items: center;
    img {
      margin: 0 8px;
    }
  }

  &:last-child {
    border-right: 0;
    border-bottom: 0.5px solid ${scarpaGray200};
  }
`;

const StateSeller = styled.p.attrs({
  className: 'StateSeller',
})<IStateSeller>`
  margin: 0;
  padding: 4px 8px;
  border-radius: 12px;
  display: table;
  font-weight: 500;
  line-height: 1;
  ${(props) => {
    switch (props.status) {
      case 'activo':
        return `
          background-color: ${turquoise50};
          color: ${turquoise600};
        `;
      case 'vacaciones':
        return `
          background-color: ${skyBlue100};
          color: ${blueLinks};
        `;
      case 'incapacidad':
        return `
          background-color: ${tomatoCoral100};
          color: ${tomatoCoral500};
        `;
      default:
        return ``;
    }
  }}
`;

const MailSeller = styled.p.attrs({
  className: 'MailSeller',
})`
  margin: 0;
  color: ${blueLinks};
`;

export {
  TableStyled,
  TheadStyled,
  TbodyStyled,
  TrStyled,
  TrBodyStyled,
  ThStyled,
  TdStyled,
  StateSeller,
  MailSeller,
};
