import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from '../store';

interface ImapsState {
  value: string;
}

const initialState: ImapsState = {
  value: '',
};

export const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setValueIdZone: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setValueIdZone } = mapsSlice.actions;

export const selectMapsId = (state: RootState) => state.maps;

export default mapsSlice.reducer;
