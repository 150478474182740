import axios, { type AxiosRequestConfig } from 'axios';
import { TOKEN } from 'shared/constants';
const CONTENT_TYPE = 'application/json';
const API_KEY = process.env.REACT_APP_API_KEY;
const URL_BASE = process.env.REACT_APP_API_URL;
const COGNITO_API_MX_URL = process.env.REACT_APP_COGNITO_MX_URL;
const COGNITO_API_KEY = process.env.REACT_APP_COGNITO_API_KEY;

export const HEADER_SELLERS: AxiosRequestConfig = {
  baseURL: URL_BASE,
  responseType: 'json',
  headers: {
    'x-api-key': API_KEY,
    'Content-Type': CONTENT_TYPE,
    Authorization: `Bearer ${TOKEN?.token}`,
  },
  params: {
    country: localStorage.getItem('country'),
  },
};

export const HEADER_SELLERS_CLEAN: AxiosRequestConfig = {
  baseURL: URL_BASE,
  responseType: 'json',
  headers: {
    'x-api-key': API_KEY,
    'Content-Type': CONTENT_TYPE,
    Authorization: `Bearer ${TOKEN?.token}`,
  },
};

export const COGNITO_MX: AxiosRequestConfig = {
  baseURL: COGNITO_API_MX_URL,
  responseType: 'json',
  headers: {
    'x-api-key': COGNITO_API_KEY,
    'Content-Type': CONTENT_TYPE,
    Authorization: `Bearer ${TOKEN?.token}`,
  },
  params: {
    country: localStorage.getItem('country'),
  },
};

export const COGNITO_MX_CLEAN: AxiosRequestConfig = {
  baseURL: COGNITO_API_MX_URL,
  responseType: 'json',
  headers: {
    'x-api-key': COGNITO_API_KEY,
    'Content-Type': CONTENT_TYPE,
    Authorization: `Bearer ${TOKEN?.token}`,
  },
};

export const HEADER_TEAMS: AxiosRequestConfig = {
  baseURL: COGNITO_API_MX_URL,
  responseType: 'json',
  headers: {
    'x-api-key': COGNITO_API_KEY,
    'Content-Type': CONTENT_TYPE,
    Authorization: `Bearer ${TOKEN?.token}`,
  },
  params: {
    country: localStorage.getItem('country'),
  },
};

const MHUBP = 'Este correo no existe en hubspot';
const MGS_EXIST = 'El correo ya existe';

export const ResponseSeller = axios.create(HEADER_SELLERS);
export const ResponseSellerLogin = axios.create(HEADER_SELLERS_CLEAN);
export const ResponseSellerMX = axios.create(COGNITO_MX_CLEAN);
export const CognitoMxAxios = axios.create(COGNITO_MX);
export const ServicesMx = axios.create(HEADER_TEAMS);

async function handleResponseError(error: any, originalRequest: any) {
  if (
    error?.response?.request?.status === 401 ||
    error?.code === 'ERR_NETWORK'
  ) {
    window.location.replace('/404');
    return new Response(originalRequest);
  }
  if (error?.response?.request?.status === 424) {
    localStorage.setItem('MESSAGE_ERROR', MHUBP);
  }
  if (error?.response?.request?.status === 400) {
    localStorage.setItem('MESSAGE_ERROR', MGS_EXIST);
  }

  return await Promise.reject(error);
}

ResponseSeller.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    return await handleResponseError(error, originalRequest);
  }
);

ServicesMx.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    return await handleResponseError(error, originalRequest);
  }
);

CognitoMxAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    return await handleResponseError(error, originalRequest);
  }
);
