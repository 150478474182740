import styled from 'styled-components';

import {
  white,
  scarpaGray200,
  scarpaGray800,
  monserratRegular,
  electricPurple600,
  robotoRegular,
  scarpaGray500,
} from '@habitech/shared';

export const WrapperLayout = styled.div`
  .nav {
    height: 65px;
    position: fixed;
    background-color: ${white};
    z-index: 101;
    inset: 0;
    border-bottom: 1px solid ${scarpaGray200};
    display: flex;
    align-items: center;
    .nav_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }
  .children {
    padding: 70px 0px 0;
  }
`;

export const StyledLogoContent = styled.div.attrs({
  className: 'StyledLogoContent',
})`
  display: flex;
  align-items: center;
`;

export const StyledTitleNav = styled.p.attrs({
  className: 'StyledTitleNav',
})`
  color: ${electricPurple600};
  font-family: ${monserratRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledDivider = styled.div`
  height: 65px;
  width: 1px;
  background-color: ${scarpaGray200};
  margin: 0 16px;
`;
export const RoleName = styled.div`
  color: ${electricPurple600};
  font-family: ${monserratRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
`;

export const LogoutText = styled.div`
  font-family: ${robotoRegular};
  font-size: 14px;
  color: ${scarpaGray500};
  font-weight: 400;
`;

export const StyledLogoutNav = styled.div.attrs({
  className: 'StyledLogoutNav',
})`
  display: flex;
  gap: 10px;

  .ghost {
    box-shadow: 0px 2px 4px 0px rgba(48, 49, 51, 0.1),
      0px 0px 1px 0px rgba(48, 49, 51, 0.05);
    padding: 8px 6px;
    color: ${scarpaGray800};
    font-size: 14px;

    &:nth-child(1) {
      padding-right: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }
  }
`;
