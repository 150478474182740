import { useRef } from 'react';
import useScript from 'hook/useScript';
import { type IGoogleLogin } from './model';

const GoogleButton = ({
  onGoogleSignIn,
  text = 'signin_with',
}: IGoogleLogin) => {
  const googleSignInButton = useRef(null);

  useScript('https://accounts.google.com/gsi/client', () => {
    (window as any).google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_KEY,
      callback: onGoogleSignIn,
    });
    (window as any).google.accounts.id.renderButton(
      googleSignInButton.current,
      {
        theme: 'outline',
        size: 'large',
        text,
        width: 260,
      }
    );
  });

  return (
    <div
      id='gb'
      className='google-auth'
      ref={googleSignInButton}
      data-testid='google-button'
    ></div>
  );
};

export default GoogleButton;
