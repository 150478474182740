import { COUNTRY_VAL } from 'shared/constants';
import {
  monserratRegular,
  robotoRegular,
  scarpaGray200,
  scarpaGray400,
  scarpaGray800,
  size,
  scarpaGray50,
  green600,
  turquoise600,
  warning,
  error,
} from '@habitech/shared';
import styled from 'styled-components';
interface ITitleTable {
  country?: string;
}

export const WrapperTable = styled.div<ITitleTable>`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0 24px;
  }
  .container-table {
    min-width: 580px;
    margin-bottom: 25px;
  }
  .container-return {
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .container-search-button {
      align-items: center;
      display: flex;
      .wrapper-search {
        margin-left: 42px;
        min-width: 280px;
      }
    }
  }
  .title-name {
    color: ${scarpaGray800} !important;
    font-family: ${monserratRegular};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .container-table > .filter-table {
    display: flex;
    padding: 18px 3px;
    justify-content: space-between;
    .filter-container {
      display: flex;
      align-items: center;
      gap: 7px;
    }
    .text-filter {
      gap: 6px;
      padding: 0px;
      display: flex;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      color: ${scarpaGray400};
      font-family: ${robotoRegular};
    }
  }
  .title-table {
    display: grid;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 0.5px solid #d3d2d4;
    background-color: ${scarpaGray50};
    grid-template-columns: ${({ country }) =>
      country === COUNTRY_VAL.CO
        ? '20% 25% 15% 15% 15% 10%'
        : '1.6fr 1.6fr 1fr 1fr 0.8fr !important'};
    border-bottom: 0.5px solid ${scarpaGray200};
    .item-general {
      padding: 16px;
      gap: 9px;
      display: flex;
      align-items: center;
      font-weight: 600;
      align-items: right;
      justify-content: space-between;

      font-family: ${monserratRegular};
      border-right: 0.5px solid ${scarpaGray200};
    }

    .item-general:last-child {
      border-right: none; /* Quita el borde derecho al último elemento */
    }
  }
  .info-table {
    display: grid;
    grid-template-rows: auto 1fr auto;
    position: relative;
    top: -0.5px;
    .row-table {
      border: 0.5px solid #d3d2d4;
      border-radius: 0 0 8px 8px;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (min-width: ${size.tablet.min}) {
        overflow-y: auto;
      }
      @media (min-width: ${size.desktop.min}) {
        overflow-y: auto;
      }
    }

    @media (min-width: ${size.tablet.min}) {
    }
    @media (min-width: ${size.desktop.min}) {
    }
  }
  .search-container {
    display: flex;
    gap: 3px;
    align-items: center;
  }
  .no-result-img {
    display: flex;
    text-align: center;
    flex-direction: column;
    .imagen-no-result {
      max-height: 400px;
      width: auto;
      margin: auto;
    }
    .title-no-result {
      font-size: 20px;
      font-weight: 600;
      font-family: ${monserratRegular};
    }
  }
  .status-color {
    display: flex;
    align-items: center;
    gap: 6px;
    .status-pin-active {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${green600};
    }
    .status-pin-inactive {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${error};
    }
    .status-pin-other {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${warning};
    }
  }
  .actions {
    display: inline-flex;
    align-items: center;
  }
`;

export const SellerNoTeam = styled.p.attrs({
  className: 'SellerNoTeam',
})`
  margin: 0;
  font-family: ${robotoRegular};
  font-weight: 600;
  color: ${turquoise600};
`