import { type IUserGoogle } from './loginGoogle';

const useLogin = () => {
  const handleGoogleError = (e: unknown) => {
    console.error(e);
  };

  const onLoginSuccess = (user: IUserGoogle) => {
    credentialLogin(user);
  };

  function credentialLogin(user: IUserGoogle) {
    localStorage.setItem('user', JSON.stringify({ ...user, isAuth: true }));
    window.location.reload();
  }

  return {
    credentialLogin,
    onLoginSuccess,
    handleGoogleError,
  };
};

export default useLogin;
