import React, { type Dispatch, type SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'store/slice/userSlice';

import {
  ColumnPagination,
  LimitSelect,
  PageButton,
  PaginationWrapper,
  TextPagination,
} from './Pagination.styles';

import { icoPrevPage, icoNextPage, icoLastPage, icoFirstPage } from 'image';

interface PaginationProps {
  totalPages: number;
  onPageChange: (page: number) => void;
  onLimitChange: (limit: number) => void;
  limitOptions: number[];
  defaultLimit?: number;
  setCurrentLimit: Dispatch<SetStateAction<number>>;
  currentLimit: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalLength?: number;
}

const Pagination = ({
  totalPages,
  onPageChange,
  onLimitChange,
  limitOptions,
  setCurrentLimit,
  currentLimit,
  currentPage,
  setCurrentPage,
  totalLength = 1,
}: PaginationProps) => {

  const { isLoading } = useSelector(selectIsLoading);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const limit = parseInt(event.target.value);
    setCurrentLimit(limit);
    onLimitChange(limit);
  };

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const maxPagesToShow = 3;
  const lastPage = pageNumbers[pageNumbers.length - 1];
  let pagesToShow = [];

  const minPage = Math.max(currentPage - 2, 0);
  const maxPage = Math.min(minPage + maxPagesToShow, lastPage);

  pagesToShow = pageNumbers.slice(minPage, maxPage);

  return (
    <PaginationWrapper>
      <ColumnPagination
        padding='10%'
        width="70%"
        alignItems='end'
      >
        { currentPage !== 1 &&
          <>
            <PageButton
              disabled={!isLoading}
              onClick={() => {
                handlePageChange(1);
              }}
            >
              <img
                src={icoFirstPage}
                alt="icon first page"
              />
            </PageButton>
            <PageButton
              disabled={!isLoading}
              onClick={() => {
                if (currentPage <= lastPage && currentPage > 1) {
                  handlePageChange(currentPage - 1);
                }
              }}
            >
              <img
                src={icoPrevPage}
                alt="icon previous page"
              />
            </PageButton>
          </>
        }
        {currentPage > maxPagesToShow && (
          <>
            <PageButton
              disabled={!isLoading}
              onClick={() => {
                handlePageChange(1);
              }}
            >
              1
            </PageButton>
            {currentPage > maxPagesToShow - 1 && <span>...</span>}
          </>
        )}
        {pagesToShow.map((page) => (
          <PageButton
            key={page}
            active={page === currentPage}
            disabled={!isLoading}
            onClick={() => {
              handlePageChange(page);
            }}
          >
            {page}
          </PageButton>
        ))}
        {currentPage < lastPage - maxPagesToShow + 1 && (
          <>
            {currentPage < lastPage - maxPagesToShow + 1 && <span>...</span>}
            <PageButton
              disabled={!isLoading}
              onClick={() => {
                handlePageChange(lastPage);
              }}
            >
              {lastPage}
            </PageButton>
          </>
        )}
        {
          currentPage !== lastPage &&
          <>
            <PageButton
              disabled={!isLoading}
              onClick={() => {
                if (currentPage < lastPage) {
                  handlePageChange(currentPage + 1);
                }
              }}
            >
              <img
                src={icoNextPage}
                alt="icon next page"
              />
            </PageButton>
            <PageButton
              disabled={!isLoading}
              onClick={() => {
                handlePageChange(lastPage);
              }}
            >
              <img
                src={icoLastPage}
                alt="icon last page"
              />
            </PageButton>
          </>
        }
      </ColumnPagination>
      <ColumnPagination
        width='25%'
        alignItems='center'
      >
        <TextPagination>Resultados por página</TextPagination>
        <LimitSelect
          value={currentLimit}
          disabled={!isLoading}
          onChange={handleLimitChange}
        >
          {limitOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </LimitSelect>
        <TextPagination>de {totalLength}</TextPagination>
      </ColumnPagination>

    </PaginationWrapper>
  );
};

export default Pagination;
