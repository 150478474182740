import { lockClose } from 'image';
import {
  Label,
  InputFake,
  ContainerInputFake,
} from './InputLockedCounterDays.styles';
import { type IInputLockedCounterDays } from './model';
const InputLockedCounterDays = ({
  label,
  modelName,
  counter,
}: IInputLockedCounterDays) => {
  return (
    <ContainerInputFake>
      <Label>{label}</Label>
      <InputFake>
        <div className='internal'>
          <div className='model-name'>{modelName}</div>
          <div className='locked-counter'>
            <div className='text'>
              {counter} {counter === 1 ? 'día' : ' días'} para editar
            </div>
            <img src={lockClose} alt='close image' />
          </div>
        </div>
      </InputFake>
    </ContainerInputFake>
  );
};

export default InputLockedCounterDays;
