import { useEffect } from 'react';
import { Wrapper } from './Toast.styles';
import { type IToastMessage } from './models';

export const Toast = ({
  title,
  subtitle,
  icon = 'imgWarning',
  type = '',
  onClose,
}: IToastMessage) => {
  useEffect(() => {
    const time = setTimeout(() => {
      onClose();
    }, 3000);
    return () => {
      clearTimeout(time);
    };
  }, [title, subtitle]);

  return (
    <Wrapper type={type}>
      <div className='wp-data'>
        <div className='icon_toast'>
          <img src={icon} alt='Icono de toast' />
        </div>
        <div className='info_toast'>
          <p className='title_toast'>{title}</p>
          <p className='subtitle_toast'>{subtitle}</p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Toast;
