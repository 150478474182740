import { size, white } from '@habitech/shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: ${white};
  width: 100vw;
  height: 100vh;
  @media (min-width: ${size.tablet.min}) {
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  padding: 2rem;

  @media (max-width: 768px) {
    max-width: none;
    background-color: transparent;
    box-shadow: none;
    padding: 1rem;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
`;
