import { Button, DropdownButton } from '@habitech/call-to-action';
import { SimpleTextField } from '@habitech/keychain-atoms-inputs';
import { Loader } from '@habitech/loader';
import { imgGoBackPurple } from 'image';
import { TEXT_FORM_MATURES_ZONES } from './CardForm.utils';
import Maps from 'custom/mapsPolygos/Maps';
import useCartFormMature from 'pages/maturesZones/hooks/useCartFormMature';
import {
  // ContainerFormZones,
  Header,
  FormCard,
  TitleCard,
  SubtitleCard,
  Line,
  MapCard,
  ReelJump,
  mapStyles,
  CenteredDiv,
  FooterButton,
  LabelName,
} from './CardForm.styles';
import SelectionMultipleOptions from '../SelectionMultipleOptions/SelectionMultipleOptions';

const CardForm = () => {
  const {
    routerMaturesHome,
    loader,
    loaderTwo,
    isEdit,
    cities,
    setCityId,
    dataToEdit,
    options,
    setOptions,
    label,
    handleChangeLabel,
    removeSpecialCharactersAndSpaces,
    setActive,
    active,
    handleUpdateData,
    handleSaveData,
    validData,
    showMap,
    handlePrintMap,
  } = useCartFormMature();
  return (
    <div>
      <Header>
        <Button
          dataId='btn-redirect-home'
          variant='ghost'
          iconLeft={
            <img src={imgGoBackPurple} alt='regresar a home' width={36} />
          }
          onClick={routerMaturesHome}
        >
          Regresar
        </Button>
      </Header>
      <div className='grid-habi'>
        {loader ? (
          <CenteredDiv>
            <Loader />
          </CenteredDiv>
        ) : (
          <>
            <FormCard>
              <TitleCard>
                {isEdit
                  ? TEXT_FORM_MATURES_ZONES.titleEdit
                  : TEXT_FORM_MATURES_ZONES.titleCreate}
              </TitleCard>
              <SubtitleCard>
                {!isEdit && TEXT_FORM_MATURES_ZONES.subtitleCreate}
              </SubtitleCard>
              <Line />
              <DropdownButton
                dataId='test-id'
                variant='white'
                listOptions={cities}
                setValue={(value) => {
                  setCityId(value);
                }}
                size='extra-large fluid'
                placeholder='Ciudad'
                label='Ciudad'
                defaultSelected={isEdit ? dataToEdit.city_id : ''}
              />
              <ReelJump />

              <SelectionMultipleOptions
                title='Zonas medianas'
                description='Selecciona las zonas maduras que quieres agregar'
                options={options}
                setOptions={setOptions}
                loading={loaderTwo}
                type='primary'
                dataLoaded={true}
              />

              <SimpleTextField
                defaultValue=''
                name='Label'
                label='Nombre equipo'
                value={label}
                placeholder='Bogota norte'
                onChange={handleChangeLabel}
                maxLength={28}
              />
              <ReelJump />
              <LabelName>
                <b>Nombre en BBDD: </b>
                {removeSpecialCharactersAndSpaces(label)}
              </LabelName>

              <ReelJump />
              <DropdownButton
                dataId='test-id'
                variant='white'
                listOptions={[
                  { value: '1', label: 'Activo' },
                  { value: '0', label: 'Inactivo' },
                ]}
                setValue={(value) => {
                  setActive(value);
                }}
                size='extra-large fluid'
                placeholder='Estado'
                label='Estado'
                defaultSelected={isEdit ? active : ''}
              />
              <ReelJump />
              <Line />
              <FooterButton>
                <Button
                  dataId='save'
                  variant='primary'
                  size='large'
                  onClick={isEdit ? handleUpdateData : handleSaveData}
                  disabled={!validData}
                >
                  {isEdit ? 'Guardar cambios' : 'Guardar zona'}
                </Button>
              </FooterButton>
            </FormCard>
          </>
        )}
        <MapCard>
          {showMap && (
            <Maps
              data={handlePrintMap as any}
              width='100%'
              border-radius='12px'
              mapStyles={mapStyles}
            />
          )}
        </MapCard>
      </div>
    </div>
  );
};

export default CardForm;
