import styled from 'styled-components';

import {
  robotoRegular,
  turquoise600,
  tomatoCoral50,
  turquoise50,
  error,
} from '@habitech/shared';

interface IStatus {
  status: number;
}
export const StyledLabelStatus = styled.span.attrs({
  className: 'StyledLabelStatus',
})<IStatus>`
  color: ${(props) => (props.status === 1 ? turquoise600 : error)};
  background-color: ${(props) =>
    props.status === 1 ? turquoise50 : tomatoCoral50};
  font-family: ${robotoRegular};
  font-size: 14px;
  font-weight: 500;
  padding: 6px 8px;
  align-items: center;
  border-radius: 12px;
  display: table;
  line-height: 0.65;
`;
