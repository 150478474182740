import {
  scarpaGray200,
  scarpaGray500,
  scarpaGray600,
  scarpaGray800,
  error,
  monserratRegular,
  robotoRegular,
  size,
  disabledBorder,
  scarpaGray50,
} from '@habitech/shared';
import styled, { keyframes } from 'styled-components';

export const Subtitle = styled.div`
  color: ${scarpaGray500};
  font-family: ${robotoRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 16px 0 16px 0;
`;
export const Return = styled.div`
  margin-top: 16px;
`;

export const ContainerForm = styled.div`
  height: 480px;
  border-top: 0.5px solid ${scarpaGray200};
  border-bottom: 0.5px solid ${scarpaGray200};
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 8px;
  overflow: auto;
  input[name='email']:disabled {
    border: 0;
    appearance: none;
    -webkit-appearance: none;
    border: 1px dashed ${disabledBorder};
    background: ${scarpaGray50};
  }
`;
export const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
`;
export const LineSeparator = styled.div`
  border-top: 0.5px solid ${scarpaGray200};
  margin-bottom: 16px;
`;
export const ItemCard = styled.div`
  .container-items {
    padding: 16px;
    display: flex;
    margin: 0 16px;
    border-bottom: 0.5px solid ${scarpaGray200};
    .item {
      width: 160px;
      color: ${scarpaGray600};
      font-family: ${robotoRegular};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
    .value {
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
`;
export const WrapperForm = styled.div.attrs({
  className: 'WrapperForm',
})`
  grid-column: 4 / 10;
  display: flex;
  flex-direction: column;
  .title-form {
    color: ${scarpaGray800};
    font-family: ${monserratRegular};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .button-form {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: end;
    gap: 1rem;
  }
  .label {
    gap: 3px;
    display: flex;
    font-size: 14px;
    align-items: center;
    color: ${scarpaGray800};
    font-family: ${robotoRegular};
    .required {
      color: ${error};
    }
  }
  .leader-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .leader-container {
      display: flex;
    }
  }
  p {
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  @media screen and (max-width: ${size.tablet.max}) {
    grid-column: 3 / 7;
  }
`;
const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  `;

export const RotatingImage = styled.img`
  animation: ${rotateAnimation} 2s linear infinite;
  margin: 0 auto;
`;
export const DarkText = styled.span`
  color: ${scarpaGray500};
  font-family: ${robotoRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const LigthText = styled.span`
  color: ${scarpaGray500};
  font-family: ${robotoRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const NameInformation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: ${robotoRegular};
  font-size: 14px;
  padding: 16px 0;
  border-bottom: 0.5px solid ${scarpaGray200};
  .title {
    font-weight: 700;
    margin-right: 10px;
    color: ${scarpaGray600};
  }
  .name {
    font-weight: 400;
    color: ${scarpaGray500};
  }
`;
