import { type ReactNode, useEffect } from 'react';
import {
  StyledDivider,
  StyledLogoContent,
  StyledLogoutNav,
  StyledTitleNav,
  WrapperLayout,
  RoleName,
  LogoutText,
} from './Layout.styles';
import { WrapperHabi } from '@habitech/shared';
import { getLocalStorageItem } from 'utils/funcionHelper';
import { Logo } from '@habitech/keychain-atoms-display';
import {
  ROUTER_URL,
  TOKEN,
  COUNTRY_VAL,
  ROLE_NAMES,
  ROLES,
} from 'shared/constants';
import { NavLink, useNavigate } from 'react-router-dom';
import { type IUserAuth } from 'components/login/Login';
import { Button } from '@habitech/call-to-action';
import { imgColombia, flagMx } from 'image';

interface ILayout {
  children: ReactNode;
}

const Layout = ({ children }: ILayout) => {
  const { role } = getLocalStorageItem('TOKEN') ?? {};
  const navigator = useNavigate();
  const userAuth: IUserAuth = JSON?.parse(
    localStorage.getItem('user') as string
  );

  const country = String(localStorage.getItem('country'));

  const flagCountry: string =
    country === COUNTRY_VAL.CO ? imgColombia : flagMx;
  const nameCountry: string =
    country === COUNTRY_VAL.CO ? COUNTRY_VAL.CO : 'MX';
  const logoHabi: string = country === COUNTRY_VAL.CO ? 'habi' : 'tuhabi';

  useEffect(() => {
    if (!userAuth?.isAuth || !TOKEN) {
      localStorage.clear();
      navigator(ROUTER_URL.LOGIN);
    }
  }, [userAuth]);
  const logout = () => {
    localStorage.clear();
    navigator(ROUTER_URL.LOGIN);
  };
  return (
    <WrapperLayout>
      <div className='nav'>
        <WrapperHabi className='container-habi'>
          <div className='nav_container'>
            <StyledLogoContent>
              <NavLink to={ROUTER_URL.ACTIONS}>
                <Logo size='small' type={logoHabi} />
              </NavLink>
              <StyledDivider />
              <StyledTitleNav>Asignación</StyledTitleNav>
            </StyledLogoContent>
            <StyledLogoutNav>
              <RoleName>
                {role === ROLES.TEAM_BO ? ROLE_NAMES.BO : ROLE_NAMES.COMMERCIAL}
              </RoleName>
              <Button
                dataId='test-id'
                iconRight={
                  <img src={flagCountry} alt={`Pais ${nameCountry}`} />
                }
                variant='ghost'
                size='large'
              ></Button>
              <Button
                iconLeft={
                  <img
                    src={userAuth?.imageProfile}
                    alt='Imagen de perfil'
                    className='img-profile'
                  />
                }
                dataId='test-id'
                variant='ghost'
                size='large'
                onClick={logout}
              >
                <LogoutText>Cerrar Sesión</LogoutText>
              </Button>
            </StyledLogoutNav>
          </div>
        </WrapperHabi>
      </div>
      <WrapperHabi className='container-habi grid-habi'>
        <div className='children grid-full'>{children}</div>
      </WrapperHabi>
    </WrapperLayout>
  );
};

export default Layout;
