import { type RootState } from '../store';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataCatalogues: {
    metropolitan_area: [
      {
        id: 0,
        label: '',
        cities: [
          {
            id: 0,
            label: '',
            area_metropolitana_id: 0,
          },
        ],
      },
    ],
    cities: [{ id: 0, name: '', label: '', cities: [] }],
    seller_groups: [
      {
        active: 0,
        city_id: 1,
        id: 1,
        label: '',
        median_zone_ids: [],
        name: '',
        total_sellers: 0,
      },
    ],
    seller_states: [
      { id: 1, name: 'activo', label: 'Activo' },
      { id: 4, name: 'vacaciones', label: 'Vacaciones' },
      { id: 5, name: 'incapacidad', label: 'Incapacidad' },
    ],
    seller_roles: [
      { id: 0, name: 'seller', label: 'Seller' },
      { id: 1, name: 'líder', label: 'Líder' },
    ],
    type_documents: [{ id: 0, name: '', label: '' }],
    seller_zone_matures: [
      {
        active: 0,
        id: 0,
        label: '',
        name: '',
        poligono: '',
        total_sellers: 0,
        zone_median_id: 0,
      },
    ],
    califications_seller: [{ id: 0, name: '' }],
  },
  dataUser: {
    pagination: 0,
    users: [
      {
        id: 0,
        name: '',
        type_document_id: null,
        document: null,
        phone: '',
        company_phone: '',
        email: '',
        seller_state_id: 0,
        date_on: '',
        vid: 0,
        calification_seller_id: 0,
        calification_seller_label: '',
        leader_seller: 0,
        seller_group_id: 0,
        preferences: {
          zones: [
            {
              value: '',
              label: '',
            },
          ],
        },
        total_assigned: 0,
        hs_owner_id: null,
        closures: 0,
        massacres: 0,
        city_id: 0,
        image: '',
        client_calification: null,
        type_document_name: null,
        type_document_label: null,
        seller_state_label: '',
        seller_group_label: '',
        team_name: '',
        seller_status_hs: '',
        zone_median_group: null,
        ready_to_assing_by_group: {
          flag: false,
          cause: '',
        },
        seller_zones_matures: [
          {
            zone_median_id: 0,
            label: '',
            exist_in_zone_matures: 0,
            active: 0,
            id: 0,
            name: '',
            poligono: '',
            total_sellers: 0,
          },
        ],
      },
    ],
  },
  dataUserFilter: {
    cityId: null,
    area_metropolitana_id: null,
    groupId: null,
    medianZoneId: null,
    sellerStateId: null,
    leader: null,
  },
  isLoading: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setDataCatalogues: (state, action) => {
      state.dataCatalogues = action.payload;
    },
    setDataUser: (state, action) => {
      state.dataUser = action.payload;
    },
    setDataUserFilter: (state, action) => {
      state.dataUserFilter = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setDataCatalogues,
  setDataUser,
  setDataUserFilter,
  setIsLoading,
} = usersSlice.actions;

// Selectors
export const selectCatalogues = (state: RootState) => state.user.dataCatalogues;
export const selectUser = (state: RootState) => state.user.dataUser;
export const selectFilter = (state: RootState) => state.user.dataUserFilter;
export const selectIsLoading = (state: RootState) => state.user;

export default usersSlice.reducer;
