import {
  monserratRegular,
  robotoRegular,
  scarpaGray200,
  scarpaGray500,
  scarpaGray600,
  scarpaGray800,
} from '@habitech/shared';
import styled from 'styled-components';

export const TeamManageFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${scarpaGray800};
  width: 100%;
  height: 74vh;
`;
export const Separator = styled.div`
  margin-bottom: 4px;
`;

export const FormTitle = styled.div`
  .title {
    font-family: ${monserratRegular};
    font-size: 24px;
    font-weight: 600;
  }
  .subtitle {
    color: ${scarpaGray500};
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    strong {
      font-weight: 600;
    }
  }
`;

export const FormDivision = styled.div`
  width: 100%;
  height: 0.5px;
  background: ${scarpaGray200};
  margin: 16px 0;
`;

export const FormContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 4px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const FormItem = styled.div`
  width: 100%;
  margin: 16px 0;
  position: relative;
`;

export const FormItemAssignation = styled.div.attrs({
  className: 'FormItemAssignation',
})`
  width: 100%;
  margin: 16px 0;
  position: relative;

  > div {
    > div {
      > div {
        transform: translateY(-140%);
        max-height: 200px !important;
      }
    }
  }
`;

export const ErrorMessage = styled.div`
  padding: 4px 0px 0px 6px;
  display: flex;
  align-items: center;
  height: 12px;
  p {
    margin-left: 6px;
    color: rgb(229, 23, 23);
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 12px;
  }
`;

export const FormFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
  border-top: 0.5px solid ${scarpaGray200};
  padding-top: 18px;
  button {
    margin-left: 20px;
  }
`;

export const ResumenContainer = styled.div`
  width: 100%;
`;

export const ResumenDivider = styled.div`
  width: 100%;
  height: 0.5px;
  background: ${scarpaGray200};
  margin: 16px 0px;
`;

export const ResumenItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${robotoRegular};
  font-size: 14px;
  .title {
    width: 35%;
    font-weight: 700;
    color: ${scarpaGray600};
  }
  .description {
    width: 65%;
    font-weight: 400;
    color: ${scarpaGray500};
  }
`;
