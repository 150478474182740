import { StyledButtonDelete } from './ButtonDelete.styles'
import { trash } from 'image'
import { type IButtonDeleteProps } from './model'

export const ButtonDelete = ({ onClick }: IButtonDeleteProps) => {
  return (
    <StyledButtonDelete
      data-testid='button-delete'
      onClick={onClick}
    >
      <img
        src={trash}
        alt="icono borrar"
      />
    </StyledButtonDelete>
  )
}
