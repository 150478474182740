import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { type IUserAuth } from 'components/login/Login';
import LoginGoogle from 'components/login/loginGoogle';
import useLogin from 'components/login/useLogin';
import {
  ROLES,
  ROUTER_URL,
  TOKEN,
  COUNTRY_VAL,
  COUNTRY,
} from 'shared/constants';

import {
  CountryContainer,
  CountryWrapper,
  HomeLeftContainer,
  HomeLoadingContent,
  HomePrincipalContainer,
  HomeRightContainer,
  LoginContainer,
  LoginWrapper,
  RotatingImage,
  StyledErrorLogin,
} from './styles';

import {
  imgMexico,
  imgColombia,
  imgLoading,
  returnArrow,
} from 'image';

const SelectCountry = () => {
  const navigate = useNavigate();
  const { onLoginSuccess, handleGoogleError } = useLogin();
  const [country, setCountry] = useState('');
  const [selectCountry, setSelectCountry] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = useState('');

  const handleClick = (country: string) => {
    localStorage.setItem('country', country);
    localStorage.setItem('model_assignation', "1");
    setCountry(country);
  };

  useEffect(() => {
    const userAuth: IUserAuth = JSON?.parse(
      localStorage.getItem('user') as string
    );

    if (userAuth?.isAuth) {
      if (TOKEN?.role === ROLES.TEAM_BO && COUNTRY === COUNTRY_VAL.MX) {
        navigate(ROUTER_URL.ACTIONS);
      } else {
        navigate(ROUTER_URL.ACTIONS);
      }
    }
  }, []);

  return (
    <HomePrincipalContainer>
      {!isLogin ? (
        <>
          <HomeLeftContainer>
            <LoginContainer>
              {selectCountry && (
                <div className="information">
                  <img src={returnArrow} className='return' alt="" onClick={() => {
                    setSelectCountry(false);
                    setCountry('');
                    setError('');
                  }} />
                  <img src={country === 'MX' ? imgMexico : imgColombia} width={30} height={30} alt="" />
                </div>
              )}

              <div className="title">Portal <br></br> asignadores</div>
              {!selectCountry && (
                <>
                  <div className="subtitle">
                    Te damos la bienvenida, a continuación <span className='strong'>selecciona el país</span> con el que quieres ingresar.
                  </div>
                  <CountryWrapper>
                    <CountryContainer
                      onClick={() => {
                        handleClick('MX');
                        setSelectCountry(true);
                        setError('');
                      }}
                      className={`${country === 'MX' ? 'active' : ''}`}
                    >
                      <img src={imgMexico} alt='TuHabi méxico' />
                      <span>México</span>
                    </CountryContainer>
                    <CountryContainer
                      onClick={() => {
                        handleClick('CO');
                        setSelectCountry(true);
                        setError('');
                      }}
                      className={`${country === 'CO' ? 'active' : ''}`}
                    >
                      <img src={imgColombia} alt='Habi Colombia' />
                      <span>Colombia</span>
                    </CountryContainer>
                  </CountryWrapper>
                </>
              )}

              {country !== '' && (
                <>
                  <div className="subtitle">
                    Ahora ingresa con tu correo empresarial
                  </div>
                  <LoginWrapper>
                    <>
                      <LoginGoogle
                        onError={handleGoogleError}
                        onNextStep={onLoginSuccess}
                        setIsLogin={setIsLogin}
                        setError={setError}
                      />
                      {error !== '' && (
                        <StyledErrorLogin>
                          <p>{error}</p>
                        </StyledErrorLogin>
                      )}
                    </>
                  </LoginWrapper>
                </>
              )}
            </LoginContainer>
          </HomeLeftContainer>
          <HomeRightContainer></HomeRightContainer>
        </>
      ) : (
        <HomeLoadingContent>
          <RotatingImage src={imgLoading} width={32} height={32} alt='cargando' />
        </HomeLoadingContent>
      )}
    </HomePrincipalContainer>
  );
};

export default SelectCountry;
