import { Button, DropdownButton } from '@habitech/call-to-action';
import { Loader } from '@habitech/loader';
import { HabiModal } from '@habitech/habi-modal';
import { imgGoBackPurple } from 'image';
import { Search } from '@habitech/keychain-atoms-inputs';
import Maps from 'custom/mapsPolygos/Maps';
import useCartFormLeader from 'pages/maturesZones/hooks/useCartFormMedianZone';
import { Helmet } from 'react-helmet';
import {
  Header,
  FormCard,
  TitleCard,
  Line,
  MapCard,
  ReelJump,
  ContainerMedianZone,
  CenteredDiv,
  mapStyles,
  SubtitleCard,
  WrapperContentMedianZone,
  FooterMedianZone,
} from './CardForm.styles';
import { ContainerSelectors, SubTitle, Title } from '../SelectionMultipleOptions/SelectionMultipleOptions.styles';
import ContainerAssignSeller from '../SelectionMultipleOptions/ButtonContainerAssignSeller';
import SelectionMultipleOptions from '../SelectionMultipleOptions/SelectionMultipleOptions';

const CardFormLeader = () => {
  const {
    loader,
    loaderThree,
    disabledMedianZone,
    isSendUpdate,
    loadingZone,
    assignZone,
    openModalSave,
    sellersAssigned,
    sellersAvailable,
    setSellersAssigned,
    setDisabledMedianZone,
    setValidUpdate,
    setOpenModalSave,
    showMap,
    buttons,
    validUpdate,
    setButtons,
    buttonsTwo,
    setButtonsTwo,
    cities,
    filterZones,
    setCitieId,
    setMedianZoneID,
    zoneMap,
    medianZoneId,
    routerHome,
    onCancelEditing,
    handleFilterChange,
    handleAssignToZone,
    handleUpdateData,
  } = useCartFormLeader();
  return (
    <div>
      <Helmet>
        <title>Zonas Medianas</title>
      </Helmet>
      <Header>
        <Button
          dataId='btn-redirect-home'
          variant='ghost'
          iconLeft={
            <img src={imgGoBackPurple} alt='regresar a home' width={36} />
          }
          onClick={onCancelEditing}
        >
          Regresar
        </Button>
      </Header>
      <div className='grid-habi'>
        {loader ? (
          <CenteredDiv>
            <Loader />
          </CenteredDiv>
        ) : (
          <FormCard>
            <>
              <Header>
                <TitleCard>
                  Asignación de Seller por zona mediana
                </TitleCard>
                <SubtitleCard>
                  Primero selecciona la ciudad y zona mediana a la cual quieres asignar un Seller.
                </SubtitleCard>
                <Line />
              </Header>
              <WrapperContentMedianZone>
                <DropdownButton
                  dataId='test-id'
                  variant='white'
                  listOptions={cities}
                  setValue={(value) => {
                    setCitieId(value);
                  }}
                  size='large fluid'
                  placeholder='Ciudad'
                  label='Ciudad'
                  defaultSelected={''}
                />
                <ReelJump />
                { loadingZone ?
                  (
                    <CenteredDiv style={{ gridColumn: '1/1' }}>
                      <Loader />
                    </CenteredDiv>
                  ) : (
                    <Search
                      label='Zonas medianas'
                      dataId='test-id'
                      listOptions={filterZones}
                      onChange={(name, value) => {
                        handleFilterChange(value);
                      }}
                      onSelectItem={(item) => {
                        setMedianZoneID(item.value);
                        setDisabledMedianZone(false);
                      }}
                      name='zone-search'
                      placeHolder='Zonas Medianas'
                      disabled={disabledMedianZone}
                      defaultValue={""}
                    />
                  )
                }
                <ReelJump />
                <ContainerSelectors
                  type='primary'
                >
                  <Title>Sellers asignados</Title>

                  <SubTitle>
                    {sellersAssigned}
                  </SubTitle>
                  <ContainerMedianZone>
                    { loaderThree ? (
                      <CenteredDiv style={{ gridColumn: '1/1' }}>
                        <Loader />
                      </CenteredDiv>
                    ) : (
                      buttons.length > 0 &&
                        <ContainerAssignSeller
                          buttons={buttons}
                          setButtons={setButtons}
                          widthButtons={'100%'}
                          characters={16}
                          buttonsTwo={buttonsTwo}
                          setButtonsTwo={setButtonsTwo}
                          setValidUpdate={setValidUpdate}
                          setSellersAssigned={setSellersAssigned}
                        />
                    )}
                  </ContainerMedianZone>
                </ContainerSelectors>
                <div>
                  <SelectionMultipleOptions
                    dataLoaded={medianZoneId}
                    options={buttonsTwo}
                    setOptions={setButtonsTwo}
                    loading={ loaderThree }
                    title='Sellers disponibles'
                    description={sellersAvailable}
                    type='secondary'
                  />
                  { buttonsTwo.length > 0 && medianZoneId &&
                    <Button
                      dataId='btn-redirect-home'
                      onClick={handleAssignToZone}
                      size='large'
                      disabled={!assignZone}
                    >
                      Asignar a zona
                    </Button>
                  }
                  <ReelJump />
                </div>
              </WrapperContentMedianZone>
              <FooterMedianZone>
                <Button
                  dataId='save'
                  variant='primary'
                  size='large'
                  onClick={handleUpdateData}
                  disabled={ !validUpdate || !isSendUpdate }
                >
                  Actualizar
                </Button>
              </FooterMedianZone>
            </>
          </FormCard>
        )}
        <MapCard>
          {showMap && (
            <Maps
              data={zoneMap}
              width='100%'
              border-radius='12px'
              mapStyles={mapStyles}
            />
          )}
        </MapCard>
      </div>
      <HabiModal
        dataId='modale'
        closeOnClickOutside
        onClickPrimary={routerHome}
        onClickSecondary={() => {
          setOpenModalSave(false);
        }}
        onClose={() => {
          setOpenModalSave(false);
        }}
        primaryBtn='Regresar'
        secondaryBtn='Cancelar'
        subtitle='Todos los cambios se perderán'
        title='¿Estás seguro que quieres regresar sin actualizar?'
        open={openModalSave}
      />
    </div>
  );
};

export default CardFormLeader;
