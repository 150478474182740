import { useEffect, useState } from 'react';
import { useToast } from 'hook/useToast';
import { ROUTER_URL } from 'shared/constants';
import { imgSuccess } from 'image';
import { useNavigate } from 'react-router-dom';
import {
  getCities,
  getMedianZoneByID,
  getSellerByZone,
  getSellerByCityId,
  updateSellerMedianZone,
} from 'service/mature/mature.api';
import {
  type IPerson,
  type IDataItem,
  type IDropdown,
  type IButtons,
} from 'pages/maturesZones/model';
import { TEXT_LABEL_ASSIGN_SELLER, TEXT_LABEL_AVAILABLE_SELLER } from '../components/cardForm/CardForm.utils';

const useCartFormMedianZone = () => {
  const navigator = useNavigate();
  const { showToast } = useToast();
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderTwo, setLoaderTwo] = useState<boolean>(false);
  const [loaderThree, setLoaderThree] = useState<boolean>(false);
  const [disabledMedianZone, setDisabledMedianZone] = useState<boolean>(true);
  const [loadingZone, setLoadingZone ] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(true);
  const [isSendUpdate, setIsSendUpdate] = useState<boolean>(true);
  const [buttons, setButtons] = useState<IButtons[]>([]);
  const [buttonsTwo, setButtonsTwo] = useState<IButtons[]>([]);
  const [assignZone, setAssignZone] = useState<boolean>(false);
  const [validUpdate , setValidUpdate ] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [openModalSave, setOpenModalSave] = useState<boolean>(false);
  const [cities, setCities] = useState<IDropdown[]>([
    { value: '1', label: '' },
  ]);
  const [medianZones, setMedianZones] = useState<IDropdown[]>([
    { value: '1', label: '' },
  ]);
  const [filterZones, setFilterZones] = useState<IDropdown[]>([
    { value: '1', label: '' },
  ]);
  const [citieId, setCitieId] = useState<string | any>();
  const [medianZoneId, setMedianZoneID] = useState<string | any>();
  const [zoneMap, setZoneMap] = useState<any>([]);
  const [sellersAssigned, setSellersAssigned] = useState<string>(TEXT_LABEL_ASSIGN_SELLER.empty);
  const [sellersAvailable, setSellersAvailable] = useState<string>(TEXT_LABEL_AVAILABLE_SELLER.empty);

  const routerHome = () => {
    navigator(ROUTER_URL.ACTIONS);
  };
  const onOpenModalSave = () => {
    setOpenModalSave(true);
  }
  useEffect(() => {
    handleCallCities();
  }, []);
  useEffect(() => {
    if (citieId) {
      handleGetMedianZone();
      handleCallSellersByCity();
      setIsEdited(true);
      setButtons([]);
      setButtonsTwo([]);
      setMedianZoneID("");
      setSellersAssigned(TEXT_LABEL_ASSIGN_SELLER.empty);
      setSellersAvailable(TEXT_LABEL_AVAILABLE_SELLER.empty);
      setValidUpdate(false);
    }
  }, [citieId]);
  useEffect(() => {
    if (medianZoneId) {
      handleShowZoneMap();
      handleCallSellers();
    }
  }, [medianZoneId]);
  useEffect(() => {
    setShowMap(false);
    setTimeout(() => {
      setShowMap(true);
    }, 10);
  }, [zoneMap]);
  const handleGetMedianZone = async () => {
    setLoadingZone(true);
    try {
      let pagination = 0;
      let calls;
      const newZonas: any[] = [];

      do {
        const response = await getMedianZoneByID({
          country: 'CO',
          city_id: citieId,
          pagination,
        });
        if (!calls) {
          calls = response.data.data.total_pages;
        }
        if (calls >= 0) {
          pagination += 75;
        }
        calls -= 1;
        newZonas.push(...response.data.data.median_zone);
      } while (calls >= 1);
      const newData = newZonas.map(
        (item: { id: any; label: any; poligono: any }) => {
          return {
            value: item.id,
            label: item.label ? item.label : 'No/name',
            selected: false,
            coordinates: item.poligono,
          };
        }
      );
      setMedianZones(newData);
      setDisabledMedianZone(false);
      setLoadingZone(false);
    } catch (error) {}
  };

  const handleFilterChange = (filterValue: string) => {
    const filteredData = medianZones.filter((item) =>
      item.label.toLowerCase().includes(filterValue.toLowerCase())
    );
    setFilterZones(filteredData);
  };

  const handleShowZoneMap = () => {
    const item = medianZones.find((element) => element.value === medianZoneId);
    if (item) {
      setZoneMap([item]);
    }
  };

  const handleCallCities = async () => {
    setLoader(true);
    try {
      const { data } = await getCities({
        country: 'CO',
      });

      const newData = data.data.map((item: { id: string; label: string }) => {
        return { value: item.id, label: item.label };
      });
      setCities(newData);
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCallSellers = async () => {
    setLoaderThree(true);
    try {
      const { data } = await getSellerByZone({
        country: 'CO',
        zone_median_id: medianZoneId,
      });
      const newData = data.data.map((item: { id: any; name: any }) => {
        return {
          id: item.id,
          label: item.name ? item.name : 'No/name',
          selected: false,
        };
      });
      setButtons(newData);
      setLoaderThree(false);
      setButtonsTwo(handleDeleteItems(buttonsTwo, newData));
      
      newData.length === 0
        ? setSellersAssigned(TEXT_LABEL_ASSIGN_SELLER.unselected)
        : setSellersAssigned(TEXT_LABEL_ASSIGN_SELLER.selected)

      buttonsTwo.length === 0
        ? setSellersAvailable(TEXT_LABEL_AVAILABLE_SELLER.selected)
        : setSellersAvailable(TEXT_LABEL_AVAILABLE_SELLER.unselected)
      
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteItems = (
    originalArray: IDataItem[],
    itemsToDelete: IDataItem[]
  ) => {
    const updatedArray = originalArray.filter(
      (item) => !itemsToDelete.some((el) => el.id === item.id)
    );
    return updatedArray;
  };

  const handleCallSellersByCity = async () => {
    setLoaderTwo(true);
    try {
      const { data } = await getSellerByCityId({
        country: 'CO',
        city_id: citieId,
      });
      const newData = data.data.map((item: { id: any; name: any }) => {
        return {
          id: item.id,
          label: item.name ? item.name : 'No/name',
          selected: false,
        };
      });
      setButtonsTwo(newData);
      setLoaderTwo(false);

    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeSelectedFalse = (array: IPerson[]): IPerson[] => {
    const newArray = array.map((obj) => ({
      ...obj,
      selected: false,
    }));

    return newArray;
  };

  const handleMakeAvailable = (buttons: any) => {
    const selected = buttons.filter(
      (button: { selected: any }) => button.selected
    );
    const noSelected = buttons.filter(
      (button: { selected: any }) => !button.selected
    );
    setButtons(noSelected);
    setButtonsTwo([...buttonsTwo, ...handleChangeSelectedFalse(selected)]);
  };

  const handleAssignToZone = () => {
    const selected = buttonsTwo.filter(
      (button: { selected: any }) => button.selected
    );
    const noSelected = buttonsTwo.filter(
      (button: { selected: any }) => !button.selected
    );
    setButtonsTwo(noSelected);
    setButtons([...buttons, ...handleChangeSelectedFalse(selected)]);
    setSellersAssigned(TEXT_LABEL_ASSIGN_SELLER.selected)
  };

  const handleActivateButton = (arreglo: IPerson[]): boolean => {
    return !arreglo.some((obj) => obj.selected);
  };

  const handleCreateSellersToUpdate = () => {
    const newData = buttons.map((item) => item.id);
    return newData;
  };

  const handleUpdateData = async () => {
    setIsSendUpdate(false);
    const sellersIds = handleCreateSellersToUpdate();
    const dataToSave = {
      country: 'CO',
      sellers_ids: sellersIds,
    };
    try {
      await updateSellerMedianZone(Number(medianZoneId), dataToSave);
      showToast({
        title: 'Actualizado',
        subtitle: 'Se ha actualizado correctamente',
        icon: imgSuccess,
        type: '',
      });
    } catch (error) {
      console.error(error);
    } finally {
      routerHome();
      setIsSendUpdate(true);
    }
  };

  const validateAvailableSellers = () => {
    const selected = buttonsTwo.filter((el) => el.selected);
    if( selected.length > 0 ) {
      setAssignZone(true)
      setValidUpdate(true);
    } else {
      setAssignZone(false);
    }
  };

  const onCancelEditing = () => {
    if(isEdited) {
      onOpenModalSave()
    } else {
      routerHome()
    }
  }

  useEffect(() => {
    validateAvailableSellers();
    if(medianZoneId) {
      buttonsTwo.length === 0
        ? setSellersAvailable(TEXT_LABEL_AVAILABLE_SELLER.selected)
        : setSellersAvailable(TEXT_LABEL_AVAILABLE_SELLER.unselected)
    }
  }, [buttonsTwo]);

  return {
    loader,
    loaderTwo,
    loaderThree,
    disabledMedianZone,
    isSendUpdate,
    medianZones,
    loadingZone,
    assignZone,
    openModalSave,
    medianZoneId,
    sellersAssigned,
    sellersAvailable,
    setOpenModalSave,
    onOpenModalSave,
    setDisabledMedianZone,
    setSellersAssigned,
    showMap,
    buttons,
    validUpdate,
    setButtons,
    buttonsTwo,
    isEdited,
    setButtonsTwo,
    cities,
    filterZones,
    setCitieId,
    setMedianZoneID,
    setAssignZone,
    setValidUpdate,
    zoneMap,
    routerHome,
    handleFilterChange,
    handleMakeAvailable,
    handleAssignToZone,
    handleActivateButton,
    handleUpdateData,
    onCancelEditing
  };
};

export default useCartFormMedianZone;
