import {
  spanishViridian50,
  scarpaGray50,
  scarpaGray200,
} from '@habitech/shared';
export const mapStyles = {
  mapTypeControl: false,
  streetViewControl: false,
  styles: [
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#e5e9fb',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: scarpaGray50 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: scarpaGray200,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: scarpaGray200,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: scarpaGray200,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#696969',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: spanishViridian50,
        },
      ],
    },
  ],
};
